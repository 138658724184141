import React, { useState } from 'react';
import styled from 'styled-components';

import QuestionIcon from '@/assets/common/question.png';
import FlexWrap from '@/components/common/FlexWrap';
import { getComma } from '@/util/common';

type RefundSummaryProps = {
  data: {
    count: number;
    totalPrice: number;
    ratio: number;
  };
};
function RefundSummary({ data }: RefundSummaryProps) {
  const { count, totalPrice, ratio } = data;
  const [isActiveTooltip, setIsActiveTooltip] = useState(false);

  return (
    <Container>
      <GrayCell>환급건수</GrayCell>
      <Cell>{count}건</Cell>
      <GrayCell>고객결제금액</GrayCell>
      <Cell>{totalPrice && getComma(totalPrice)}</Cell>
      <GrayCell>
        <CellWrapper>
          <QuestionWrapper onMouseLeave={() => setIsActiveTooltip(false)}>
            <QuestionImg
              onMouseOver={() => setIsActiveTooltip(true)}
              src={QuestionIcon}
            />
          </QuestionWrapper>
          매출비중
          {isActiveTooltip && (
            <Tooltip>
              <Arrow />
              <TooltipContent>
                {`매출 비중은 선택 된 결제 금액을 고객 결제 금액으로 나눈 값입니다.\n매출비중 = (선택 된 결제 금액 / 고객 결제 금액)\n*선택된 결제 금액 : 위 필터를 적용한 고객결제금액\n*결제 금액 : 선택 기간 내 고객 결제 금액`}
              </TooltipContent>
            </Tooltip>
          )}
        </CellWrapper>
      </GrayCell>
      <Cell>{(ratio * 100).toFixed(2)}%</Cell>
    </Container>
  );
}

const Container = styled(FlexWrap)`
  border-bottom: 1px solid #bdbdbd;
  border-left: 1px solid #bdbdbd;
  margin-bottom: 20px;
`;
const Cell = styled(FlexWrap)`
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 36px;
  height: 36px;
  flex: 1;
  border-top: 1px solid #bdbdbd;
  border-right: 1px solid #bdbdbd;
  background-color: #ffffff;
`;
const GrayCell = styled(Cell)`
  background-color: #eaf0fa;
`;
const QuestionWrapper = styled(FlexWrap)<{ onMouseLeave: any }>`
  width: 25px;
  height: 36px;
  align-items: center;
`;
const QuestionImg = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-right: 4px;
  margin-bottom: 2px;
`;
const CellWrapper = styled(FlexWrap)`
  position: relative;
`;
const Tooltip = styled.div`
  position: absolute;
  top: 120%;
  left: -43px;
  background-color: #212121;
  width: 420px;
  z-index: 10;
  border-radius: 4px;
  padding: 8px 12px;
`;
const TooltipContent = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #ffffff;
  white-space: pre-wrap;
  text-align: left;
`;
const Arrow = styled.div`
  position: absolute;
  top: -19px;
  left: 48px;
  width: 10px;
  height: 11px;
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #212121;
`;
export default RefundSummary;
