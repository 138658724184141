import { memo } from 'react';
import {
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryBar,
  VictoryLabel,
} from 'victory';
import { format } from 'date-fns';
import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import Text from '@/components/common/Text';
import PriceWrap from '@/components/salesReport/PriceWrap';
import TableSection from '@/components/salesReport/TableSection';
import Variation from '@/components/salesReport/Variation';
import Tooltip from '@/components/salesReport/Tooltip';
import { getComma } from '@/util/common';
import { getDayOfWeek, getVariationData } from '@/util/salesReport';
import { GetReportDataResponse } from '@/types/refund';

type TopSectionType = {
  data: GetReportDataResponse['top'];
};
function TopSection({ data }: TopSectionType) {
  const now = new Date();
  const { lastDayHourTotal, lastDayTotal, thisDayHourTotal, thisDayTotal } =
    data;
  const dayOfWeek = getDayOfWeek();
  const isPriceVariationPerDay = lastDayTotal === 0 && thisDayTotal > 0;
  const isPriceVariationPerHour =
    lastDayHourTotal === 0 && thisDayHourTotal > 0;

  const label = {
    prev: `지난주\n${format(
      new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
      'MM/dd'
    )}`,
    curr: `오늘\n${format(now, 'MM/dd')}`,
  };

  const { percent, sign } = getVariationData(
    lastDayTotal || 0,
    thisDayTotal || 0
  );

  return (
    <TableSection
      title='지난주 환급대비'
      dateStr={`${format(new Date(), 'yyyy년 M월 d일')} ${dayOfWeek}요일`}
    >
      <FlexWrap>
        <PriceContainer dir='column'>
          <Text margin='0 0 4px'>{`지난주 ${dayOfWeek}요일보다`}</Text>
          <Text weight={500} size={24}>
            총 상품금액
          </Text>
          <Variation
            percent={isPriceVariationPerDay ? thisDayTotal : percent}
            textSize={24}
            textLineHeight={32}
            iconSize={8}
            sign={sign}
            gap={8}
            unit={isPriceVariationPerDay ? '원' : '%'}
          />
          <PriceContentBox>
            <PriceContent>
              <PriceText>{`지난주 ${dayOfWeek}요일 총 상품금액`}</PriceText>
              <PriceWrap price={lastDayTotal} style={{ marginRight: 10 }} />
            </PriceContent>
            <PriceContent>
              <PriceText>오늘 총 상품금액</PriceText>
              <PriceWrap price={thisDayTotal} style={{ marginRight: 10 }} />
            </PriceContent>
          </PriceContentBox>
        </PriceContainer>
        <GraphContainer>
          <VictoryChart
            theme={VictoryTheme.material}
            domainPadding={{ x: 60, y: 20 }}
            style={{
              parent: {
                marginTop: 20,
              },
            }}
            padding={{ top: -22, bottom: 20, right: 0, left: 50 }}
            height={100}
          >
            <VictoryAxis
              offsetX={50}
              style={{
                axis: {
                  stroke: '#F5F6F7',
                },
                tickLabels: {
                  textAlign: 'center',
                  fontSize: 4,
                },
                ticks: {
                  size: 3,
                  padding: -2,
                },
              }}
            />
            <VictoryAxis
              offsetY={20}
              dependentAxis
              minDomain={0}
              tickCount={2}
              tickFormat={(t) => `${t < 1 ? t * 1e12 : t / 10000}만원`}
              style={{
                axis: {
                  stroke: '#000000',
                },
                tickLabels: {
                  fontSize: 4,
                },
                ticks: {
                  padding: -2,
                  size: 3,
                },
              }}
            />
            <VictoryBar
              horizontal
              cornerRadius={2}
              style={{
                data: {
                  fill: ({ datum }) =>
                    datum.x === label.prev ? '#A7C4FB' : '#246CF6',
                },
                //@ts-ignore
                labels: {
                  fontSize: 5,
                  fontWeight: 500,
                  lineHeight: 18,
                  //@ts-ignore
                  fill: ({ datum }) => {
                    return percent >= 200
                      ? datum.x === label.curr
                        ? '#FFFFFF'
                        : '#1856CD'
                      : percent < 50
                      ? datum.x === label.curr
                        ? '#FFFFFF'
                        : '#1856CD'
                      : '#1856CD';
                  },
                },
                parent: {
                  borderWidth: 0,
                },
              }}
              data={[
                {
                  x: label.curr,
                  y: thisDayTotal,
                  label:
                    thisDayTotal === 0
                      ? ''
                      : `${format(now, 'HH시 mm분')} 기준 ${getComma(
                          thisDayTotal
                        )}원`,
                },
                {
                  x: label.prev,
                  y: lastDayTotal,
                  label:
                    lastDayTotal === 0
                      ? ''
                      : `총 상품 금액 ${getComma(lastDayTotal)}원`,
                },
              ]}
              barRatio={0.9}
              labelComponent={
                <VictoryLabel
                  textAnchor={({ datum }) =>
                    percent >= 200
                      ? datum.x === label.curr
                        ? 'end'
                        : 'start'
                      : percent < 50
                      ? 'end'
                      : datum.x === label.curr
                      ? 'start'
                      : 'end'
                  }
                  dx={({ datum }) =>
                    percent >= 200
                      ? datum.x === label.curr
                        ? -10
                        : 10
                      : percent < 50
                      ? '-10'
                      : datum.x === label.curr
                      ? 10
                      : -10
                  }
                  verticalAnchor='middle'
                />
              }
            />
          </VictoryChart>
        </GraphContainer>
      </FlexWrap>
    </TableSection>
  );
}

const PriceContainer = styled(FlexWrap)`
  width: 355px;
`;
const PriceContentBox = styled(FlexWrap)`
  background-color: #f5f6f7;
  border-radius: 4px;
  border: 1px solid #e5e6e8;
  margin-top: 50px;
  width: 335px;
`;
const PriceContent = styled(FlexWrap)`
  flex-direction: column;
  flex: 1;
  padding: 16px 8px;
  align-items: flex-end;
  :first-child {
    border-right: 1px solid #e5e6e8;
  }
`;
const GraphContainer = styled.div`
  position: relative;
  flex: 1;
`;
const PriceText = styled.span`
  width: 100%;
  font-size: 14px;
  line-height: 14px;
  color: #5f6165;
  margin: 0 0 12px;
  text-align: center;
  @media ${(props) => props.theme.laptop} {
    font-size: 12px;
    line-height: 12px;
  }
`;

export default memo(TopSection);
