import { ReactNode } from 'react';
import styled from 'styled-components';

type TextProps = {
  children: ReactNode;
  color?: string;
  size?: number;
  lineHeight?: number;
  weight?: number;
  margin?: string;
  padding?: string;
  center?: boolean;
  width?: string;
};
function Text({ children, ...textProps }: TextProps) {
  return <StyledP {...textProps}>{children}</StyledP>;
}
const StyledP = styled.p<TextProps>`
  width: ${(props) => props.width || 'auto'};
  color: ${(props) => props.color || '#00000'};
  color: ${(props) => props.color || '#00000'};
  font-size: ${(props) => props.size || 16}px;
  line-height: ${(props) => props.size + 'px' || '160%'};
  font-weight: ${(props) => props.weight || 400};
  margin: ${(props) => props.margin || 0};
  padding: ${(props) => props.padding || 0};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;
export default Text;
