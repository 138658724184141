import React from 'react';
import styled from 'styled-components';

import FileDownload from '@/assets/common/file_download.png';
import {
  RequiredDateGraphList,
  RequiredDateHeaderTitle,
} from '@/constants/refundStatement';

function RequiredDate() {
  const onClickFileDownload = (file: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = file;
    downloadLink.download = 'KTP 부가세신고 퀵 가이드_가맹점용';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <TableHeader>
        {RequiredDateHeaderTitle.map((item, idx) => (
          <Header key={idx} flex={item.flex}>
            <Title>{item.title}</Title>
          </Header>
        ))}
      </TableHeader>
      <GraphList>
        {RequiredDateGraphList.map((item, idx) => (
          <Graph key={idx}>
            <Content flex={1}>{idx + 1}</Content>
            <Content style={{ justifyContent: 'flex-start' }} flex={15}>
              <RequiredText>필수</RequiredText>
              {item.fileName}
            </Content>
            <Content flex={2.5}>
              <Downalod onClick={() => onClickFileDownload(item.file)}>
                <DownloadIcon src={FileDownload} />
                파일 다운로드
              </Downalod>
            </Content>
          </Graph>
        ))}
      </GraphList>
    </>
  );
}

const TableHeader = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background-color: ${(props) => props.theme.blueColors.scale2};
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
`;
const Header = styled.div<{ flex: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  position: relative;
  flex: ${(props) => props.flex};
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  :last-child {
    border-right: none;
  }
`;
const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
const GraphList = styled.ul``;
const Graph = styled.li`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};
  background-color: ${(props) => props.theme.pointColors.white};
`;
const Content = styled.div<{ flex: number }>`
  display: flex;
  flex: ${(props) => props.flex};
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  :first-child {
    border-left: 1px solid ${(props) => props.theme.grayColors.scale3};
  }
`;
const RequiredText = styled.span`
  margin: 0 5px 0 20px;
  padding: 0 12px;
  border-radius: 6px;
  background-color: #0b42ad;
  color: #ffffff;
`;
const Downalod = styled.div`
  display: flex;
  align-items: center;
  color: #0b42ad;
  cursor: pointer;
`;
const DownloadIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 4px;
`;
export default RequiredDate;
