import Refund from '@/assets/common/gnb_refund.png';
import RefundActive from '@/assets/common/gnb_refund_active.png';
import SalesReport from '@/assets/common/gnb_sales_report.png';
import SalesReportActive from '@/assets/common/gnb_sales_report_active.png';
import RefundDetail from '@/assets/common/refund_detail.png';
import RefundDetailActive from '@/assets/common/refund_detail_active.png';
import GlobalPaymentActive from '@/assets/common/gnb_global_payment_active.png';
import GlobalPayment from '@/assets/common/gnb_global_payment.png';

export const SideNavigationInfo = [
  {
    id: 1,
    title: '환급 현황',
    link: '/',
    defaultIcon: Refund,
    activeIcon: RefundActive,
  },
  {
    id: 2,
    title: '매출 리포트',
    link: '/salesReport',
    defaultIcon: SalesReport,
    activeIcon: SalesReportActive,
  },
  {
    id: 3,
    title: '환급 내역서',
    link: '/refundStatement',
    defaultIcon: RefundDetail,
    activeIcon: RefundDetailActive,
  },
];

export const SideNavigationGlobalInfo = [
  {
    id: 1,
    title: '환급 현황',
    link: '/',
    defaultIcon: Refund,
    activeIcon: RefundActive,
  },
  {
    id: 2,
    title: '매출 리포트',
    link: '/salesReport',
    defaultIcon: SalesReport,
    activeIcon: SalesReportActive,
  },
  {
    id: 3,
    title: '환급 내역서',
    link: '/refundStatement',
    defaultIcon: RefundDetail,
    activeIcon: RefundDetailActive,
  },
  {
    id: 4,
    title: '해외 간편 결제',
    link: '/global-payment',
    defaultIcon: GlobalPayment,
    activeIcon: GlobalPaymentActive,
  },
];

export const SideNavigationOnlyGlobalInfo = [
  {
    id: 1,
    title: '해외 간편 결제',
    link: '/global-payment',
    defaultIcon: GlobalPayment,
    activeIcon: GlobalPaymentActive,
  },
];
