import React from 'react';
import styled from 'styled-components';

import { RefundConentType } from '@/types/home';
import RefundDetailGraphItem from './RefundDetailGraphItem';

interface IProps {
  data: Array<RefundConentType>;
  pageNumber: number;
  totalElements: number;
}

function RefundDetailGraph({ data, pageNumber, totalElements }: IProps) {
  return (
    <List>
      {data?.map((item, idx) => (
        <RefundDetailGraphItem
          key={idx}
          item={item}
          idx={idx}
          pageNumber={pageNumber}
          totalElements={totalElements}
        />
      ))}
    </List>
  );
}

const List = styled.div``;

export default RefundDetailGraph;
