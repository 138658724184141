import styled from 'styled-components';

import Increase from '@/assets/salesReport/increase.png';
import Decrease from '@/assets/salesReport/decrease.png';
import FlexWrap from '@/components/common/FlexWrap';
import Text from '@/components/common/Text';
import { getComma } from '@/util/common';

interface IProps {
  percent: number;
  textSize: number;
  textColor?: string;
  textLineHeight: number;
  iconSize: number;
  sign?: '-' | '+';
  gap?: number;
  unit?: string;
}
function Variation({
  percent,
  textSize,
  textColor,
  textLineHeight,
  iconSize,
  sign,
  gap,
  unit = '%',
}: IProps) {
  return (
    <Container>
      <Text
        size={textSize}
        color={textColor}
        margin={gap ? `0 ${gap}px 0 0` : undefined}
        lineHeight={textLineHeight}
        weight={500}
      >
        {getComma(percent) + unit + (sign ? '' : ' -')}
      </Text>
      {sign && (
        <Icon src={sign === '+' ? Increase : Decrease} size={iconSize} />
      )}
    </Container>
  );
}
const Container = styled(FlexWrap)`
  align-items: center;
`;
const Icon = styled.img<{ size: number }>`
  width: ${(props) => props.size + 2}px;
  height: ${(props) => props.size}px;
`;
export default Variation;
