import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import { useAppSelector } from '@/hooks/useReduxHooks';
import { formattedRefundStatus, getComma } from '@/util/common';

function GlobalPaymentItem({ item, idx, pageNumber, totalElements }: any) {
  const { size } = useAppSelector((state) => state.refund);
  const { userInfo } = useAppSelector((state) => state.user);
  const refundIndex = totalElements - (pageNumber * size + idx);

  return (
    <Item>
      <Content flex={1}>
        {refundIndex < 10 ? '0' + refundIndex : refundIndex}
      </Content>
      <Content flex={1.2}>
        {formattedRefundStatus(item.transactionType)}
      </Content>
      <Content flex={1.9}>
        {format(new Date(item.saleDate), 'yyyy.MM.dd HH:mm')}
      </Content>
      <Content flex={2.5}>{getComma(item.price)}</Content>
      <Content flex={1.8}>{item.payType}</Content>
      {!userInfo?.globalPayOnly && (
        <Content flex={1.3}>
          {item.refundFlag ? '환급 결제' : '해당 없음'}
        </Content>
      )}
      {!userInfo?.globalPayOnly && (
        <Content flex={1.3}>{item.refundIndex ?? '-'}</Content>
      )}
    </Item>
  );
}
const Item = styled.li`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};
  background-color: ${(props) => props.theme.pointColors.white};
  :hover {
    background-color: #f7f9fc;
  }
`;

const Content = styled.div<{ flex: number }>`
  position: relative;
  display: flex;
  flex: ${(props) => props.flex};
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 4px;
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  :first-child {
    border-left: 1px solid ${(props) => props.theme.grayColors.scale3};
  }
`;
export default GlobalPaymentItem;
