import React, { Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import FlexWrap from '../common/FlexWrap';
import mobileIcon from '@/assets/common/mobile.png';
import Icon from '../common/Icon';
import { useCertificate } from '@/hooks/useCertificate';
import { PassportChahgeProcessType } from './CheckChange';

type MobileCheckType = {
  setPassportChahgeProcess: Dispatch<SetStateAction<PassportChahgeProcessType>>;
};

function MobileCheck({ setPassportChahgeProcess }: MobileCheckType) {
  const navigate = useNavigate();
  const location = useLocation();
  const { businessNumber } = location.state;

  const { onClickCertification } = useCertificate({
    businessNumber,
    setState: setPassportChahgeProcess,
  });
  return (
    <Container>
      <Title>
        본인인증을 위해
        <br />
        휴대폰 본인인증을 해주세요.
      </Title>
      <FlexWrap dir='column' gap='12px'>
        <Button isMobile onClick={() => onClickCertification()}>
          <Icon src={mobileIcon} width='24px' height='24px' />
          휴대폰 본인인증
        </Button>
        <Button onClick={() => navigate('/')}>홈으로</Button>
      </FlexWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 42px 60px 42px 60px;
`;
const Title = styled.h2`
  margin-top: 8px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.large};
`;
const Button = styled.button<{ isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  padding: 12px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  border-radius: 2px;
  border: 1px solid #757575;
  color: ${(props) => (props.isMobile ? '#FFFFFF' : '#757575')};
  background-color: ${(props) => (props.isMobile ? '#0B42AD' : '#FFFFFF')};
`;

export default MobileCheck;
