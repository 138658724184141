import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import logo from '@/assets/common/logo.png';
import FlexWrap from '@/components/common/FlexWrap';
import SideMenuItem from '@/components/SideNavigation/SideMenuItem';
import {
  SideNavigationGlobalInfo,
  SideNavigationInfo,
  SideNavigationOnlyGlobalInfo,
} from '@/constants/sideNavigation';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { loginState } from '@/store/modules/user';

function SideNavigation() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = localStorage.getItem('name');
  const taxFreeStoreNumber = localStorage.getItem('taxFreeStoreNumber');
  const { userInfo } = useAppSelector((state) => state.user);

  const [sideNavigationList] = useState(
    userInfo?.globalPayOnly
      ? SideNavigationOnlyGlobalInfo
      : userInfo?.globalPayUse
      ? SideNavigationGlobalInfo
      : SideNavigationInfo
  );

  const logOut = () => {
    dispatch(loginState(false));
    localStorage.setItem('loginToken', '');
    navigate('/');
  };

  const onLogOut = () => {
    logOut();
    localStorage.removeItem('name');
    localStorage.removeItem('taxFreeStoreNumber');
  };

  return (
    <Container>
      <LogoContainer>
        <Logo onClick={() => navigate('/')}>
          <Image src={logo} />
        </Logo>
      </LogoContainer>
      <Profile>
        <ProfileContent>
          안녕하세요.
          <br />
          <Bold>{name}</Bold> 님
        </ProfileContent>
        {!!taxFreeStoreNumber && !userInfo?.globalPayOnly && (
          <ProfileContent>
            면세판매장번호
            <br />
            {taxFreeStoreNumber}
          </ProfileContent>
        )}
      </Profile>
      <List>
        {sideNavigationList.map((item) => (
          <Menu to={item.link} key={item.id}>
            <SideMenuItem {...item} />
          </Menu>
        ))}
      </List>
      <LogOutBtn onClick={onLogOut}>
        <span>로그아웃</span>
      </LogOutBtn>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  top: 0px;
  min-width: 280px;
  min-height: 100vh;
  background-color: #0b42ad;
  z-index: 10;
  @media ${(props) => props.theme.laptop} {
    min-width: 200px;
  }
`;
const LogoContainer = styled(FlexWrap)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  height: 80px;
  padding: 21px 40px;
  z-index: 10;
  background-color: ${(props) => props.theme.priamryColors.primary};
`;
const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const LogOutBtn = styled.button`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  padding: 10px 0px;
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: ${(props) => props.theme.lineHeight.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  border: 2px solid #c7e0f1;
  border-radius: 2px;
  color: #c7e0f1;
`;

const Profile = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
  padding: 40px;
  @media ${(props) => props.theme.laptop} {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 40px;
  }
`;
const Logo = styled.div`
  cursor: pointer;
`;
const Image = styled.img`
  width: 114px;
  height: 38px;
`;

const Menu = styled(Link)``;

const ProfileContent = styled.span`
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: ${(props) => props.theme.lineHeight.small};
  color: ${(props) => props.theme.pointColors.white};
`;
const Bold = styled.span`
  font-size: ${(props) => props.theme.fontSize.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.lineHeight.small};
  }
`;

export default SideNavigation;
