import AUS from '@/assets/salesReport/nationality/AUS.png';
import BEL from '@/assets/salesReport/nationality/BEL.png';
import BGD from '@/assets/salesReport/nationality/BGD.png';
import BRA from '@/assets/salesReport/nationality/BRA.png';
import CAN from '@/assets/salesReport/nationality/CAN.png';
import CHE from '@/assets/salesReport/nationality/CHE.png';
import CHL from '@/assets/salesReport/nationality/CHL.png';
import CHN from '@/assets/salesReport/nationality/CHN.png';
import COL from '@/assets/salesReport/nationality/COL.png';
import CUB from '@/assets/salesReport/nationality/CUB.png';
import DEU from '@/assets/salesReport/nationality/DEU.png';
import ESP from '@/assets/salesReport/nationality/ESP.png';
import FJI from '@/assets/salesReport/nationality/FJI.png';
import FRA from '@/assets/salesReport/nationality/FRA.png';
import GBR from '@/assets/salesReport/nationality/GBR.png';
import HKG from '@/assets/salesReport/nationality/HKG.png';
import IDN from '@/assets/salesReport/nationality/IDN.png';
import IND from '@/assets/salesReport/nationality/IND.png';
import ITA from '@/assets/salesReport/nationality/ITA.png';
import JPN from '@/assets/salesReport/nationality/JPN.png';
import KAZ from '@/assets/salesReport/nationality/KAZ.png';
import KIR from '@/assets/salesReport/nationality/KIR.png';
import KOR from '@/assets/salesReport/nationality/KOR.png';
import LKA from '@/assets/salesReport/nationality/LKA.png';
import MEX from '@/assets/salesReport/nationality/MEX.png';
import MMR from '@/assets/salesReport/nationality/MMR.png';
import NLD from '@/assets/salesReport/nationality/NLD.png';
import NOR from '@/assets/salesReport/nationality/NOR.png';
import NZL from '@/assets/salesReport/nationality/NZL.png';
import PAK from '@/assets/salesReport/nationality/PAK.png';
import PER from '@/assets/salesReport/nationality/PER.png';
import PHL from '@/assets/salesReport/nationality/PHL.png';
import POL from '@/assets/salesReport/nationality/POL.png';
import RUS from '@/assets/salesReport/nationality/RUS.png';
import SAU from '@/assets/salesReport/nationality/SAU.png';
import SGP from '@/assets/salesReport/nationality/SGP.png';
import SWE from '@/assets/salesReport/nationality/SWE.png';
import THA from '@/assets/salesReport/nationality/THA.png';
import TUR from '@/assets/salesReport/nationality/TUR.png';
import TWN from '@/assets/salesReport/nationality/TWN.png';
import UKR from '@/assets/salesReport/nationality/UKR.png';
import USA from '@/assets/salesReport/nationality/USA.png';
import UZB from '@/assets/salesReport/nationality/UZB.png';
import VNM from '@/assets/salesReport/nationality/VNM.png';
import ZAF from '@/assets/salesReport/nationality/ZAF.png';

export const nationInfo: any = {
	AUS: { icon: AUS, label: '오스트레일리아' },
	BEL: { icon: BEL, label: '벨기에' },
	BGD: { icon: BGD, label: '방글라데시' },
	BRA: { icon: BRA, label: '브라질' },
	CAN: { icon: CAN, label: '캐나다' },
	CHE: { icon: CHE, label: '스위스' },
	CHL: { icon: CHL, label: '칠레' },
	CHN: { icon: CHN, label: '중국' },
	COL: { icon: COL, label: '콜롬비아' },
	CUB: { icon: CUB, label: '쿠바' },
	DEU: { icon: DEU, label: '독일' },
	ESP: { icon: ESP, label: '스페인' },
	FJI: { icon: FJI, label: '피지' },
	FRA: { icon: FRA, label: '프랑스' },
	GBR: { icon: GBR, label: '영국' },
	HKG: { icon: HKG, label: '홍콩' },
	IDN: { icon: IDN, label: '인도네시아' },
	IND: { icon: IND, label: '인도' },
	ITA: { icon: ITA, label: '이탈리아' },
	JPN: { icon: JPN, label: '일본' },
	KAZ: { icon: KAZ, label: '카자흐스탄' },
	KIR: { icon: KIR, label: '키리바시' },
	KOR: { icon: KOR, label: '한국' },
	LKA: { icon: LKA, label: '스리랑카' },
	MEX: { icon: MEX, label: '멕시코' },
	MMR: { icon: MMR, label: '미얀마' },
	NLD: { icon: NLD, label: '네덜란드' },
	NOR: { icon: NOR, label: '노르웨이' },
	NZL: { icon: NZL, label: '뉴질랜드' },
	PAK: { icon: PAK, label: '파키스탄' },
	PER: { icon: PER, label: '페루' },
	PHL: { icon: PHL, label: '필리핀' },
	POL: { icon: POL, label: '폴란드' },
	RUS: { icon: RUS, label: '러시아' },
	SAU: { icon: SAU, label: '사우디아라비아' },
	SGP: { icon: SGP, label: '싱가포르' },
	SWE: { icon: SWE, label: '스웨덴' },
	THA: { icon: THA, label: '태국' },
	TUR: { icon: TUR, label: '튀르키예' },
	TWN: { icon: TWN, label: '대만' },
	UKR: { icon: UKR, label: '우크라이나' },
	USA: { icon: USA, label: '미국' },
	UZB: { icon: UZB, label: '우즈베키스탄' },
	VNM: { icon: VNM, label: '베트남' },
	ZAF: { icon: ZAF, label: '남아프리카 공화국' },
	MYS: { icon: ZAF, label: '말레이시아' },
};

type PickerData = Array<{
	value: number;
	label: string;
}>;
export const monthData: PickerData = [
	{ value: 0, label: '1월' },
	{ value: 1, label: '2월' },
	{ value: 2, label: '3월' },
	{ value: 3, label: '4월' },
	{ value: 4, label: '5월' },
	{ value: 5, label: '6월' },
	{ value: 6, label: '7월' },
	{ value: 7, label: '8월' },
	{ value: 8, label: '9월' },
	{ value: 9, label: '10월' },
	{ value: 10, label: '11월' },
	{ value: 11, label: '12월' },
];

const getLatestYear = (count: number) => {
	let result = [];
	const thisYear = new Date().getFullYear();
	for (let i = 0; i < count; i++) {
		result.push({
			value: thisYear - i,
			label: `${thisYear - i}년`,
		});
	}
	return result;
};
export const yearData = getLatestYear(10);
