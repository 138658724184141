import Teenage from '@/assets/salesReport/birth/teenage.png';
import Adult from '@/assets/salesReport/birth/adult.png';
import Parents from '@/assets/salesReport/birth/parents.png';
import GrandParents from '@/assets/salesReport/birth/grand-parents.png';

export const getDayOfWeek = (targetDate?: string) => {
  const dayArr = ['일', '월', '화', '수', '목', '금', '토'];
  const dayIndex = targetDate
    ? new Date(targetDate).getDay()
    : new Date().getDay();
  return dayArr[dayIndex];
};

export const getWeekNumber = (date: Date): number => {
  const currentDate = date.getDate();
  const firstDay = new Date(date.setDate(1)).getDay();
  return Math.ceil(
    (currentDate - (firstDay === 0 ? 1 : firstDay === 1 ? 0 : 8 - firstDay)) / 7
  );
};

export const getFullWeekLabel = (date: Date): string => {
  const weekNum = getWeekNumber(date);

  return `${date.getFullYear()}년 ${
    weekNum === 0 ? date.getMonth() : date.getMonth() + 1
  }월 ${weekNum === 0 ? 5 : weekNum}주차`;
};

export const getWeekLabel = (date: Date): string => {
  const currentDate = date.getDate();
  const firstDay = new Date(date.setDate(1)).getDay();
  const weekNum = Math.ceil((currentDate - firstDay + 1) / 7);
  return `${date.getMonth() + 1}월 ${firstDay > 4 ? weekNum + 1 : weekNum}주`;
};

type WeekPickerDataType = {
  weekPickerArr: Array<string>;
  weekDateObj: {
    [key: string]: {
      startDate: Date;
      endDate: Date;
    };
  };
  initialLabel: string;
};
export const getWeekPickerData = (count: number): WeekPickerDataType => {
  let weekDateObj: any = {};
  const getWeek = (endDate: Date, idx: number) => {
    if (idx === 0) {
      return;
    }
    const dayIndex = endDate.getDay();
    const year = endDate.getFullYear();
    const month = endDate.getMonth();
    const date = endDate.getDate();
    weekDateObj = {
      ...weekDateObj,
      [getFullWeekLabel(new Date(year, month, date - dayIndex + 1))]: {
        startDate: new Date(year, month, date - dayIndex + 1),
        endDate: new Date(year, month, date - dayIndex + 7),
      },
    };
    getWeek(new Date(year, month, date - (dayIndex + 1)), idx - 1);
  };
  getWeek(new Date(), count);
  return {
    weekPickerArr: Object.keys(weekDateObj),
    weekDateObj,
    initialLabel: Object.keys(weekDateObj)[0],
  };
};

type VariationData = { percent: number; sign?: '-' | '+' };
export const getVariationData = (prev: number, curr: number): VariationData => {
  if (prev === 0) {
    if (curr === 0) {
      return {
        percent: 0,
      };
    } else {
      return {
        percent: 0,
        sign: '+',
      };
    }
  }
  const ratio = curr / prev;
  const percent = ratio < 2 ? (curr - prev) / prev : ratio;
  return {
    percent: Math.abs(Math.round(percent * 100)),
    sign: percent === 0 ? undefined : percent < 0 ? '-' : '+',
  };
};
export const getAgeIcon = (age: number) => {
  if (age < 20) {
    return Teenage;
  } else if (age < 40) {
    return Adult;
  } else if (age < 60) {
    return Parents;
  } else {
    return GrandParents;
  }
};
export const getGraphColor = (index: number) => {
  return [
    '#A7C4FB',
    '#7CA7FA',
    '#5089F8',
    '#246CF6',
    '#1856CD',
    '#0B42AD',
    '#0E3B90',
  ][index];
};
export const getGraphColorPerMonth = (index: number): string => {
  return ['#7CA7FA', '#5089F8', '#246CF6', '#1856CD', '#0B42AD', '#0E3B90'][
    index
  ];
};
export const getTooltipTextPerWeek = (graphMapKey: string): string => {
  return new Date(graphMapKey).getDate() + '일';
};
export const getTooltipTextPerMonth = (graphMapKey: string): string => {
  const startDate = new Date(graphMapKey);
  const year = startDate.getFullYear();
  const month = startDate.getMonth();
  const date = startDate.getDate();
  const endDate = new Date(year, month, date + 6);
  return `${date}일 - ${endDate.getDate()}일`;
};
