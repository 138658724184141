import React, { useEffect } from 'react';
import styled from 'styled-components';

import {
  globalPaymentGraphHeaderContent,
  globalPaymentOnlyGraphHeaderContent,
} from '@/constants/globalPayment';
import FlexWrap from '@/components/common/FlexWrap';
import DateSelector from '@/components/common/DateSelector';
import Paging from '@/components/common/Paging';
import TableHeader from '@/components/common/TableHeader';
import LoadingView from '@/components/common/LoadingView';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { resetRefundFilter, setFilterSeletor } from '@/store/modules/refund';
import RefundCard from '@/components/globalPayment/GlobalPaymentCard';
import RefundFilterTable from '@/components/globalPayment/GlobalPaymentFilterTable';
import { useGlobalPayRefund } from '@/hooks/useGlobalPayRefund';
import RefundDetailList from '@/components/globalPayment/GlobalPaymentList';

function GlobalPayment() {
  const dispatch = useAppDispatch();
  const {
    data: refundDatas,
    totalPage,
    totalElements,
    pageNumber,
    setPageNumber,
    onDownloadExcel,
    isLoading,
  } = useGlobalPayRefund();
  const { userInfo } = useAppSelector((state) => state.user);

  useEffect(() => {
    return () => {
      dispatch(resetRefundFilter());
      dispatch(
        setFilterSeletor({ startDate: new Date(), endDate: new Date() })
      );
    };
  }, []);

  if (isLoading)
    return (
      <LoadingContainer>
        <LoadingView />
      </LoadingContainer>
    );

  return (
    <Wrapper>
      <Container>
        <Title>해외 간편 결제</Title>
        <Inner>
          <Header>
            <SubTitle>해외 간편 결제 현황</SubTitle>
            <FlexWrap alignItems='center' gap='20px'>
              <DateSelector />
              {refundDatas && (
                <Button onClick={onDownloadExcel}>파일 다운로드</Button>
              )}
            </FlexWrap>
          </Header>
          <RefundContainer>
            {refundDatas && <RefundCard refundInfo={refundDatas.summaryInfo} />}
          </RefundContainer>
          <FlexWrap dir='column'>
            <GraphTitle>결제 상세 내역</GraphTitle>
            <RefundFilterTable
              data={refundDatas!}
              setPageNumber={setPageNumber}
            />
          </FlexWrap>
          <TableHeader
            isGlobalPay
            titleList={
              userInfo?.globalPayOnly
                ? globalPaymentOnlyGraphHeaderContent
                : globalPaymentGraphHeaderContent
            }
          />
          {refundDatas ? (
            <>
              <RefundDetailList
                data={refundDatas?.detailInfoPage?.content}
                pageNumber={pageNumber}
                totalElements={totalElements}
              />
            </>
          ) : (
            <div style={{ marginTop: 20 }}>환급건이 없습니다.</div>
          )}
        </Inner>

        {refundDatas?.detailInfoPage?.content[0] && (
          <PagingContainer>
            <Paging
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPage={totalPage}
            />
          </PagingContainer>
        )}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  max-height: 100vh;
  flex: 1;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.blueColors.scale3};
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const RefundContainer = styled(FlexWrap)`
  margin-bottom: 50px;
  gap: 20px;
`;
const Inner = styled.div`
  padding: 38px 40px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin-bottom: 24px;
`;
const Title = styled.p`
  font-size: ${(props) => props.theme.fontSize.xLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
  padding: 21px 40px;
  border-bottom: 1px solid #bdbdbd;
  color: #0b42ad;
`;
const SubTitle = styled.p`
  display: flex;
  position: relative;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`;
const GraphTitle = styled.p`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 24px;
`;
const GraphSubTitle = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
`;
const PagingContainer = styled.div`
  margin: 20px 0;
`;
const Button = styled.button`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  padding: 3px 20px;
  color: #0b42ad;
  background-color: #ffffff;
  border: 2px solid #0b42ad;
  border-radius: 4px;
`;
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 1200px;
`;

const QuestionWrapper = styled(FlexWrap)<{ onMouseLeave: any }>`
  width: 25px;
  height: 36px;
  align-items: center;
`;
const QuestionImg = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-left: 4px;
  margin-bottom: 2px;
`;
const Tooltip = styled.div`
  position: absolute;
  top: 120%;
  left: 46px;
  background-color: #212121;
  width: 660px;
  z-index: 10;
  border-radius: 4px;
  padding: 8px 12px;
`;
const TooltipContent = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #ffffff;
  white-space: pre-wrap;
  text-align: left;
`;
const Arrow = styled.div`
  position: absolute;
  top: -19px;
  left: 70px;
  width: 10px;
  height: 11px;
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #212121;
`;
export default GlobalPayment;
