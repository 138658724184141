export const transactionSelector = [
  { value: null, label: '전체' },
  { value: 'APPROVAL', label: '승인' },
  { value: 'CANCEL', label: '취소' },
  { value: 'REFUND', label: '환불' },
];

export const paymentSelector = [
  { value: null, label: '전체' },
  { value: 'WECHAT', label: '위챗페이' },
  { value: 'ALIPAY', label: '알리페이' },
  { value: 'ALIPAY_PLUS', label: '알리페이 플러스' },
  { value: 'ETC', label: '기타' },
];

export const refundStatusSelector = [
  { value: null, label: '전체' },
  { value: true, label: '환급 결제' },
  { value: false, label: '해당 없음' },
];

export const globalPaymentGraphHeaderContent = [
  { id: 1, title: 'NO.', flex: 1 },
  { id: 2, title: '거래 상태', flex: 1.2 },
  { id: 3, title: '판매일자', flex: 1.9 },
  { id: 4, title: '결제 금액(원화기준)', flex: 2.5 },
  { id: 6, title: '결제 수단', flex: 1.8 },
  { id: 7, title: '환급 결제 여부', flex: 1.3 },
  { id: 8, title: '환급 번호', flex: 1.3, isActive: true },
];

export const globalPaymentOnlyGraphHeaderContent = [
  { id: 1, title: 'NO.', flex: 1 },
  { id: 2, title: '거래 상태', flex: 1.2 },
  { id: 3, title: '판매일자', flex: 1.9 },
  { id: 4, title: '결제 금액(원화기준)', flex: 2.5 },
  { id: 6, title: '결제 수단', flex: 1.8 },
];
