import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import QuestionIcon from '@/assets/common/question.png';
import ResetActive from '@/assets/common/reset_active.png';
import ResetDisabled from '@/assets/common/reset_disabled.png';
import FlexWrap from '@/components/common/FlexWrap';
import {
  paymentSelector,
  refundStatusSelector,
  transactionSelector,
} from '@/constants/globalPayment';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import {
  resetGlobalPayRefundFilter,
  setGlobalPayRefundFilter,
} from '@/store/modules/refund';
import { RefundDetailResponse } from '@/types/home';

type GlobalPaymentFilterTableProps = {
  data: RefundDetailResponse;
  setPageNumber: Dispatch<SetStateAction<number>>;
};

function GlobalPaymentFilterTable({
  setPageNumber,
}: GlobalPaymentFilterTableProps) {
  const [isActiveTooltip, setIsActiveTooltip] = useState(false);
  const dispatch = useAppDispatch();
  const { globalPayFilter } = useAppSelector((state) => state.refund);
  const { userInfo } = useAppSelector((state) => state.user);
  const { transactionType, payType, isRefund } = useAppSelector(
    (state) => state.refund.globalPayFilter
  );

  const transactionTypeRef = useRef(null);
  const payTypeRef = useRef(null);
  const isRefundRef = useRef(null);

  const isInitalFilter = Boolean(
    transactionType === null && payType === null && isRefund === null
  );

  const onReset = async () => {
    await dispatch(resetGlobalPayRefundFilter());
    if (transactionTypeRef) {
      (transactionTypeRef as any).current?.setValue({
        value: null,
        label: '전체',
      });
      (payTypeRef as any).current.setValue({
        value: null,
        label: '전체',
      });
      (isRefundRef as any).current.setValue({
        value: null,
        label: '전체',
      });
    }
  };
  const onChange = (newState: any) => {
    dispatch(
      setGlobalPayRefundFilter({
        ...globalPayFilter,
        ...newState,
      })
    );
    setPageNumber(0);
  };

  return (
    <Container>
      <Table>
        <GrayCell>거래 구분</GrayCell>
        <Cell>
          <Select
            ref={transactionTypeRef}
            isSearchable={false}
            defaultValue={{
              label: transactionType
                ? transactionSelector.find((v) => v.value === transactionType)
                    ?.label
                : '전체',
              value: transactionType,
            }}
            onChange={(e: any) => onChange({ transactionType: e.value })}
            //@ts-ignore
            options={transactionSelector}
            styles={{
              container: (baseStyles) => ({
                ...baseStyles,
                marginLeft: 'auto',
              }),
              control: () => ({
                marginLeft: 'auto',
                border: 'none',
                display: 'flex',
                height: 22,
              }),
              indicatorSeparator: () => ({
                width: 0,
              }),
            }}
          />
        </Cell>
        <GrayCell>결제 수단</GrayCell>
        <Cell>
          <Select
            ref={payTypeRef}
            isSearchable={false}
            defaultValue={{
              ...paymentSelector.filter((v) => v.value === payType)[0],
            }}
            onChange={(e: any) => onChange({ payType: e.value })}
            //@ts-ignore
            options={paymentSelector}
            styles={{
              container: (baseStyles) => ({
                ...baseStyles,
                marginLeft: 'auto',
              }),
              control: () => ({
                marginLeft: 'auto',
                border: 'none',
                display: 'flex',
                height: 22,
              }),
              indicatorSeparator: () => ({
                width: 0,
              }),
            }}
          />
        </Cell>
        {!userInfo?.globalPayOnly && (
          <>
            <GrayCell>환급 결제 여부</GrayCell>
            <Cell>
              <Select
                ref={payTypeRef}
                isSearchable={false}
                defaultValue={{
                  ...refundStatusSelector.filter(
                    (v) => v.value === isRefund
                  )[0],
                }}
                onChange={(e: any) => onChange({ isRefund: e.value })}
                //@ts-ignore
                options={refundStatusSelector}
                styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    marginLeft: 'auto',
                  }),
                  control: () => ({
                    marginLeft: 'auto',
                    border: 'none',
                    display: 'flex',
                    height: 22,
                  }),
                  indicatorSeparator: () => ({
                    width: 0,
                  }),
                }}
              />
            </Cell>
          </>
        )}
      </Table>
      <ResetBtn onClick={onReset} disabled={isInitalFilter}>
        <ResetIcon src={isInitalFilter ? ResetDisabled : ResetActive} />
        초기화
      </ResetBtn>
      <SettlementManagement
        href='https://merchant.icbnet.co.kr/'
        target='_blank'
      >
        <TooltipWraapper>
          <QuestionWrapper onMouseLeave={() => setIsActiveTooltip(false)}>
            <QuestionImg
              src={QuestionIcon}
              onMouseOver={() => setIsActiveTooltip(true)}
            />
          </QuestionWrapper>
          {isActiveTooltip && (
            <Tooltip>
              <Arrow />
              <TooltipContent>
                {
                  'ID : a사업자번호(10자리 숫자)\n비밀번호 : a사업자번호(10자리 숫자)!\n\n예시 ID : a1234567890\n예시 비밀번호 : a1234567890!'
                }
              </TooltipContent>
            </Tooltip>
          )}
        </TooltipWraapper>
        <span>정산 관리 바로가기</span>
      </SettlementManagement>
    </Container>
  );
}
const Container = styled(FlexWrap)`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`;
const Table = styled(FlexWrap)`
  border-bottom: 1px solid #cbccce;
  border-left: 1px solid #cbccce;
  margin-left: auto;
`;
const ResetBtn = styled.button`
  margin-right: 8px;
  font-size: 14px;
  line-height: 22px;
  padding: 8px 0;
  width: 110px;
  display: flex;
  justify-content: center;
  background-color: transparent;
`;
const ResetIcon = styled.img`
  height: 22px;
  width: 18px;
  margin-right: 4px;
`;
const Cell = styled.div`
  font-size: 14px;
  line-height: 22px;
  padding: 8px 0;
  width: 120px;
  border-top: 1px solid #cbccce;
  border-right: 1px solid #cbccce;
  text-align: center;
  background-color: #ffffff;
`;
const GrayCell = styled(Cell)`
  background-color: #eaf0fa;
`;
const SettlementManagement = styled.a`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  span {
    font-weight: 500;
    color: #246cf6;
    ::after {
      position: relative;
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      background-color: #246cf6;
      bottom: 2px;
    }
  }
`;
const TooltipWraapper = styled.div`
  position: relative;
`;
const QuestionWrapper = styled(FlexWrap)<{ onMouseLeave: any }>`
  width: 25px;
  height: 36px;
  align-items: center;
  margin-left: 3px;
`;
const QuestionImg = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-right: 4px;
  margin-bottom: 2px;
`;
const Tooltip = styled.div`
  position: absolute;
  top: 120%;
  left: -200px;
  background-color: #212121;
  width: 250px;
  z-index: 10;
  border-radius: 4px;
  padding: 8px 12px;
`;
const Arrow = styled.div`
  position: absolute;
  top: -19px;
  right: 30px;
  width: 10px;
  height: 11px;
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #212121;
`;
const TooltipContent = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #ffffff;
  white-space: pre-wrap;
  text-align: left;
`;
export default GlobalPaymentFilterTable;
