import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import Text from '@/components/common/Text';

type Props = {
  iconSource?: string;
  title: string;
  content?: string;
  idx: number;
};
function CustomerInfoItem({ iconSource, title, content, idx }: Props) {
  return (
    <Container idx={idx} alignItems='center'>
      <Text size={18} color='#ffffff'>
        {title}
      </Text>
      <FlexWrap flex='1' justifyContent='flex-end' alignItems='center'>
        {iconSource && <Icon src={iconSource} />}
        <Text size={18} color='#ffffff'>
          {content || '-'}
        </Text>
      </FlexWrap>
    </Container>
  );
}
const Container = styled(FlexWrap)<{ idx: number }>`
  background-color: ${(props) =>
    props.idx === 0 ? '#246CF6' : props.idx === 1 ? '#1856CD' : '#0B42AD'};

  align-items: center;
  flex: 1;
  margin-bottom: 20px;
  padding: 12px;
  height: 80px;
  border-radius: 4px;
`;
const Icon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  margin-bottom: 4px;
`;

export default CustomerInfoItem;
