import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import refundCountIcon from '@/assets/home/refund_count.png';
import totalPaymentIcon from '@/assets/home/total_payment.png';
import visitNationIcon from '@/assets/home/visit_nation.png';
import { getComma } from '@/util/common';
import { RefundSummaryResponse } from '@/types/home';
import { nationInfo } from '@/constants/salesReport';

interface IProps {
	refundInfo: RefundSummaryResponse;
}
function RefundCard({ refundInfo }: IProps) {
	const { totalCount, totalPrice, nation } = refundInfo;

	const getNationName = () => {
		if (!nation) {
			return '-';
		}

		return nationInfo?.['CTO']?.['label'] || '기타국가';
	};

	return (
		<>
			<Summary>
				<Icon src={refundCountIcon} />
				<FlexWrap dir='column' alignItems='center' flex='1'>
					<Title>환급승인 건</Title>
					<ValueWrap alignItems='center' justifyContent='center' gap='8px'>
						<ValueText>{getComma(totalCount)}</ValueText>
						<Text>건</Text>
					</ValueWrap>
				</FlexWrap>
			</Summary>
			<Summary>
				<Icon src={totalPaymentIcon} />
				<FlexWrap dir='column' alignItems='center' flex='1'>
					<Title>총 고객결제금액</Title>
					<ValueWrap alignItems='center' justifyContent='center' gap='8px'>
						<ValueText>{getComma(totalPrice)}</ValueText>
						<Text>원</Text>
					</ValueWrap>
				</FlexWrap>
			</Summary>
			<Summary>
				<Icon src={visitNationIcon} />
				<FlexWrap dir='column' alignItems='center' flex='1'>
					<Title>최대 방문 국가</Title>
					<ValueWrap alignItems='center' justifyContent='center' gap='8px'>
						<ValueText>{getNationName()}</ValueText>
					</ValueWrap>
				</FlexWrap>
			</Summary>
		</>
	);
}
const Summary = styled(FlexWrap)`
	flex-direction: column;
	align-items: center;
	gap: 8px;
	background-color: #ffffff;
	width: 100%;
	padding: 20px 40px;
	border: 1px solid #bdbdbd;
	border-radius: 4px;
`;
const Icon = styled.img`
	width: 68px;
	height: 68px;
	object-fit: contain;
	margin-bottom: 8px;
`;
const Text = styled.p`
	font-size: 16px;
	line-height: 24px;
`;
const Title = styled(Text)``;
const ValueText = styled.p`
	font-size: 28px;
	line-height: 38px;
	font-weight: 700;
	color: #0b42ad;
	margin-bottom: 3px;
`;
const ValueWrap = styled(FlexWrap)`
	width: 100%;
`;
export default RefundCard;
