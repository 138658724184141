import React, { Dispatch, SetStateAction, useRef } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import ResetActive from '@/assets/common/reset_active.png';
import ResetDisabled from '@/assets/common/reset_disabled.png';
import FlexWrap from '@/components/common/FlexWrap';
import {
  ageSelector,
  genderSelector,
  refundStatusSelector,
  nationInfo,
  paymentSelector,
} from '@/constants/refundDetail';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { resetRefundFilter, setRefundFilter } from '@/store/modules/refund';
import { RefundDetailTotalType } from '@/types/home';

type RefundFilterTableProps = {
  data: RefundDetailTotalType;
  setPageNumber: Dispatch<SetStateAction<number>>;
};
function RefundFilterTable({ data, setPageNumber }: RefundFilterTableProps) {
  const dispatch = useAppDispatch();
  const { refundFilter } = useAppSelector((state) => state.refund);
  const {
    refundStatus,
    nation,
    minAge,
    maxAge,
    gender,
    employee,
    paymentMethod,
  } = useAppSelector((state) => state.refund.refundFilter);

  const employeeRef = useRef(null);
  const refundStatusSelectorRef = useRef(null);
  const genderSelectorRef = useRef(null);
  const nationSelectorRef = useRef(null);
  const ageSelectorRef = useRef(null);
  const paymentMethodRef = useRef(null);

  const isInitalFilter = Boolean(
    employee === null &&
      nation === null &&
      gender === null &&
      refundStatus === null &&
      minAge === 0 &&
      maxAge === 100 &&
      paymentMethod === null
  );

  const onReset = async () => {
    await dispatch(resetRefundFilter());
    if (refundStatusSelectorRef) {
      (employeeRef as any).current?.setValue({
        value: null,
        label: '전체',
      });
      (refundStatusSelectorRef as any).current?.setValue({
        value: null,
        label: '전체',
      });
      (genderSelectorRef as any).current.setValue({
        value: null,
        label: '전체',
      });
      (nationSelectorRef as any).current.setValue({
        value: null,
        label: '전체',
      });
      (ageSelectorRef as any).current.setValue({
        value: { minAge: 0, maxAge: 100 },
        label: '전체',
      });
      (paymentMethodRef as any).current.setValue({
        value: { minAge: 0, maxAge: 100 },
        label: '전체',
      });
    }
  };
  const onChange = (newState: any) => {
    dispatch(
      setRefundFilter({
        ...refundFilter,
        ...newState,
      })
    );
    setPageNumber(0);
  };

  return (
    <Container>
      <ResetBtn onClick={onReset} disabled={isInitalFilter}>
        <ResetIcon src={isInitalFilter ? ResetDisabled : ResetActive} />
        필터 초기화
      </ResetBtn>
      <Table>
        <GrayCell>직원</GrayCell>
        <Cell>
          <Select
            ref={employeeRef}
            isSearchable={false}
            defaultValue={{
              label: employee ? employee : '전체',
              value: employee,
            }}
            onChange={(e: any) => onChange({ employee: e.value })}
            //@ts-ignore
            options={
              data
                ? [
                    { value: null, label: '전체' },
                    ...Object.entries(data.employees || {}).map(
                      ([key, value]) => ({
                        value: key,
                        label: key + ' / ' + value,
                      })
                    ),
                  ]
                : [{ value: null, label: '전체' }]
            }
            styles={{
              container: (baseStyles) => ({
                ...baseStyles,
                marginLeft: 'auto',
              }),
              control: () => ({
                marginLeft: 'auto',
                border: 'none',
                display: 'flex',
                height: 22,
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                width: 220,
                transform: 'translateX(-110px)',
              }),
              indicatorSeparator: () => ({
                width: 0,
              }),
            }}
          />
        </Cell>
        <GrayCell>환급상태</GrayCell>
        <Cell>
          <Select
            ref={refundStatusSelectorRef}
            isSearchable={false}
            defaultValue={{
              ...refundStatusSelector.filter(
                (v) => v.value === refundStatus
              )[0],
            }}
            onChange={(e: any) => onChange({ refundStatus: e.value })}
            //@ts-ignore
            options={refundStatusSelector}
            styles={{
              container: (baseStyles) => ({
                ...baseStyles,
                marginLeft: 'auto',
              }),
              control: () => ({
                marginLeft: 'auto',
                border: 'none',
                display: 'flex',
                height: 22,
              }),
              indicatorSeparator: () => ({
                width: 0,
              }),
            }}
          />
        </Cell>
        <GrayCell>성별</GrayCell>
        <Cell>
          <Select
            ref={genderSelectorRef}
            isSearchable={false}
            defaultValue={{
              ...genderSelector.filter((v) => v.value === gender)[0],
            }}
            onChange={(e: any) => onChange({ gender: e.value })}
            //@ts-ignore
            options={genderSelector}
            styles={{
              container: (baseStyles, state) => ({
                ...baseStyles,
                marginLeft: 'auto',
              }),
              control: () => ({
                marginLeft: 'auto',
                border: 'none',
                display: 'flex',
                height: 22,
              }),
              indicatorSeparator: () => ({
                width: 0,
              }),
            }}
          />
        </Cell>
        <GrayCell>국적</GrayCell>
        <Cell>
          <Select
            ref={nationSelectorRef}
            isSearchable={false}
            defaultValue={{
              label: nation ? nationInfo[nation].label : '전체',
              value: nation,
            }}
            onChange={(e: any) => onChange({ nation: e.value })}
            //@ts-ignore
            options={
              data
                ? [
                    { value: null, label: '전체' },
                    ...data.nations?.map((v: string) => nationInfo[v]),
                  ]
                : [{ value: null, label: '전체' }]
            }
            styles={{
              container: (baseStyles) => ({
                ...baseStyles,
                marginLeft: 'auto',
              }),
              control: () => ({
                marginLeft: 'auto',
                border: 'none',
                display: 'flex',
                height: 22,
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                width: 220,
                transform: 'translateX(-110px)',
              }),
              indicatorSeparator: () => ({
                width: 0,
              }),
            }}
          />
        </Cell>
        <GrayCell>연령</GrayCell>
        <Cell>
          <Select
            ref={ageSelectorRef}
            isSearchable={false}
            defaultValue={{
              label:
                minAge === 0 && maxAge === 100 ? '전체' : `${minAge}~${maxAge}`,
              value: {
                minAge,
                maxAge,
              },
            }}
            onChange={(e: any) =>
              onChange({ minAge: e.value.minAge, maxAge: e.value.maxAge })
            }
            //@ts-ignore
            options={ageSelector}
            styles={{
              container: (baseStyles) => ({
                ...baseStyles,
                marginLeft: 'auto',
              }),
              control: () => ({
                marginLeft: 'auto',
                border: 'none',
                display: 'flex',
                height: 22,
              }),
              indicatorSeparator: () => ({
                width: 0,
              }),
            }}
          />
        </Cell>
        <GrayCell>결제수단</GrayCell>
        <Cell>
          <Select
            ref={paymentMethodRef}
            isSearchable={false}
            defaultValue={{
              ...paymentSelector.filter((v) => v.value === paymentMethod)[0],
            }}
            onChange={(e: any) => onChange({ paymentMethod: e.value })}
            //@ts-ignore
            options={paymentSelector}
            styles={{
              container: (baseStyles, state) => ({
                ...baseStyles,
                marginLeft: 'auto',
              }),
              control: () => ({
                marginLeft: 'auto',
                border: 'none',
                display: 'flex',
                height: 22,
              }),
              indicatorSeparator: () => ({
                width: 0,
              }),
            }}
          />
        </Cell>
      </Table>
    </Container>
  );
}
const Container = styled(FlexWrap)`
  margin-left: auto;
  align-items: center;
  margin-bottom: 20px;
`;
const Table = styled(FlexWrap)`
  border-bottom: 1px solid #cbccce;
  border-left: 1px solid #cbccce;
  margin-left: auto;
`;
const ResetBtn = styled.button`
  border: 1px solid #cbccce;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 14px;
  line-height: 22px;
  padding: 8px 0;
  width: 110px;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
`;
const ResetIcon = styled.img`
  height: 22px;
  width: 18px;
  margin-right: 4px;
`;
const Cell = styled.div`
  font-size: 14px;
  line-height: 22px;
  padding: 8px 0;
  width: 110px;
  border-top: 1px solid #cbccce;
  border-right: 1px solid #cbccce;
  text-align: center;
  background-color: #ffffff;
`;
const GrayCell = styled(Cell)`
  background-color: #eaf0fa;
`;
export default RefundFilterTable;
