import { useEffect, useState } from 'react';

function useInnerWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  return { innerWidth: width };
}

export default useInnerWidth;
