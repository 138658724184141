import React from 'react';
import styled from 'styled-components';

import RequiredDate from '@/components/refundStatement/RequiredDate';
import FlexWrap from '@/components/common/FlexWrap';
import RefundVatData from '@/components/refundStatement/RefundVatData';
import Paging from '@/components/common/Paging';
import useStatement from '@/hooks/useStatement';

function RefundStatement() {
  const {
    data: refundStatementData,
    totalPage,
    totalElements,
    pageNumber,
    setPageNumber,
  } = useStatement();

  return (
    <Wrapper>
      <Title>환급 내역</Title>
      <SubContainer>
        <SubTitle>필수 자료</SubTitle>
        <RequiredDate />
      </SubContainer>
      <SubContainer>
        <FlexWrap justifyContent='space-between'>
          <SubTitle>환급/부가세 신고자료</SubTitle>
          <CautionText>
            * 부가가치세신고자료는 개인사업자 1,7월 / 법인사업자는 1,4,7,10월에
            업로드됩니다.
          </CautionText>
        </FlexWrap>
        {refundStatementData && (
          <RefundVatData
            refundStatementData={refundStatementData.content}
            totalElements={totalElements}
          />
        )}
      </SubContainer>
      <Paging
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        totalPage={totalPage}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  max-height: 100vh;
  flex: 1;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.blueColors.scale3};
`;
const SubContainer = styled.div`
  padding: 36px 40px;
`;
const Title = styled.p`
  font-size: ${(props) => props.theme.fontSize.xLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
  padding: 21px 40px;
  border-bottom: 1px solid #bdbdbd;
  color: #0b42ad;
`;
const SubTitle = styled.h3`
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 24px;
  font-weight: 500;
`;
const CautionText = styled.p`
  font-size: 14px;
  line-height: 22px;
`;
export default RefundStatement;
