import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useQuery } from 'react-query';

import { excelDownload, getRefundDetail } from '@/api/refund';
import {
  ExcelDownloadParam,
  RefundDetailParam,
  RefundDetailResponse,
} from '@/types/home';
import { useAppSelector } from './useReduxHooks';

export const useRefndDetail = () => {
  const {
    filter,
    size,
    refundFilter: {
      gender,
      minAge,
      maxAge,
      nation,
      refundStatus,
      employee,
      paymentMethod,
    },
  } = useAppSelector((state) => state.refund);
  const { startDate, endDate } = useAppSelector((state) => state.refund.filter);
  const { userInfo } = useAppSelector((state) => state.user);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const pagingParam = { pageNumber, size };

  const { data, isSuccess, isLoading } = useQuery<
    RefundDetailResponse,
    RefundDetailParam
  >(
    [
      'refundList',
      startDate,
      endDate,
      pagingParam,
      refundStatus,
      gender,
      minAge,
      maxAge,
      nation,
      employee,
      paymentMethod,
    ],
    async () =>
      await getRefundDetail(
        userInfo?.franchiseeIndex as number,
        {
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
        },
        pagingParam,
        refundStatus,
        gender,
        minAge,
        maxAge,
        nation,
        employee,
        paymentMethod
      ),
    {
      onSuccess: (res) => {
        setTotalPage(res.detail.detail.totalPages);
        setTotalElements(res.detail.detail.totalElements);
      },
      retry: false,
    }
  );
  const { refetch } = useQuery<any, ExcelDownloadParam>(
    ['excelFileDownload', filter],
    async () =>
      await excelDownload(userInfo?.franchiseeIndex as number, filter),
    {
      retry: false,
      enabled: false,
      onSuccess: (s3Url) => {
        const blob = new Blob([s3Url]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${userInfo?.storeName}_${format(
          new Date(startDate),
          'yyyyMMdd'
        )}-${format(new Date(endDate), 'yyyyMMdd')}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
      },
      onError: () => {
        alert(
          '다운로드에 실패하였습니다. 아래 연락처로 문의바랍니다.\nT. 02-6275-8011 (평일 오전 8시 - 오후 5시)'
        );
      },
    }
  );

  useEffect(() => {
    setPageNumber(0);
  }, [size, startDate, endDate]);

  const onDownloadExcel = () => {
    refetch();
  };

  return {
    data,
    isLoading,
    isSuccess,
    totalPage,
    totalElements,
    pageNumber,
    setPageNumber,
    onDownloadExcel,
  };
};
