import { format } from 'date-fns';

import {
  ExcelDownloadParam,
  RefundDetailParam,
  RefundDetailResponse,
  RefundSummaryPayload,
  RefundSummaryResponse,
} from '@/types/home';
import { GetReportDataPayload, GetReportDataResponse } from '@/types/refund';
import {
  RefundRecordDetailResponse,
  RefundStatementResponse,
} from '@/types/refundStatement';
import api from '..';

// 환급내역
export const getRefundDetail = async (
  franchiseeIndex: number,
  filter: { startDate: string; endDate: string },
  pagingParam: { pageNumber: number; size: number },
  refundStatus: string | null,
  gender: 'F' | 'M' | null,
  minAge: number,
  maxAge: number,
  nation: string | null,
  employee: string | null,
  paymentMethod: string | null
) => {
  const res = await api.getAxios<RefundDetailResponse, RefundDetailParam>(
    `/franchise-web/refund`,
    {
      startDate: filter.startDate,
      endDate: filter.endDate,
      page: pagingParam.pageNumber,
      size: pagingParam.size,
      nation,
      gender,
      refundStatus,
      minAge,
      maxAge,
      employeeUserId: employee,
      paymentMethod,
    }
  );
  return res.data;
};
// 환급 요약
export const getHomeData = async (
  franchiseeIndex: number,
  filter: { startDate: string; endDate: string }
) => {
  const res = await api.getAxios<RefundSummaryResponse, RefundSummaryPayload>(
    `/franchise-web/refund/summary`,
    {
      startDate: filter.startDate,
      endDate: filter.endDate,
    }
  );
  return res.data;
};

// 환급 리포트 조회
export const getReportData = async (payload: GetReportDataPayload) => {
  const res = await api.getAxios<GetReportDataResponse, GetReportDataPayload>(
    '/franchise-web/report',
    payload
  );
  return res.data;
};
// 엑셀다운로드
export const excelDownload = async (
  franchiseeIndex: number,
  filter: { startDate: Date; endDate: Date }
) => {
  const { startDate, endDate } = filter;
  const res = await api.getAxios<any, ExcelDownloadParam>(
    `/franchise-web/refund/download`,
    {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
    { responseType: 'blob' }
  );
  return res.data;
};
// 해외결제 엑셀다운로드
export const globalPayExcelDownload = async (
  franchiseeIndex: number,
  filter: { startDate: Date; endDate: Date }
) => {
  const { startDate, endDate } = filter;
  const res = await api.getAxios<any, ExcelDownloadParam>(
    `/franchise-web/global-pay/download`,
    {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
    { responseType: 'blob' }
  );
  return res.data;
};
// 환급 내역서
export const getRefundStatement = async (franchiseeIndex: number) => {
  const res = await api.getAxios<RefundStatementResponse, null>(
    `/franchise-web/record`
  );
  return res.data;
};
// 환급 내역서 각 파일정보
export const getRefundRecordDetail = async (refundRecordIndex: number) => {
  const res = await api.getAxios<RefundRecordDetailResponse[], null>(
    `/franchise-web/record/detail/${refundRecordIndex}`
  );
  return res.data;
};
// 간편결제
export const getGlobalPaymentData = async (
  filter: { startDate: string; endDate: string },
  pagingParam: { pageNumber: number; size: number },
  transactionType: string | null,
  payType: string | null,
  isRefund: string | null
) => {
  const res = await api.getAxios<any, any>(`/franchise-web/global-pay`, {
    startDate: filter.startDate,
    endDate: filter.endDate,
    page: pagingParam.pageNumber,
    size: pagingParam.size,
    transactionType,
    payType,
    isRefund,
  });
  return res.data;
};
