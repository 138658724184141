import QuickGuide from '@/assets/refundStatement/quick_guide.pdf';

export const RequiredDateHeaderTitle = [
  { title: 'No.', flex: 1 },
  { title: '자료명', flex: 15 },
  { title: '다운로드', flex: 2.5 },
];

export const RequiredDateGraphList = [
  { fileName: 'KTP 부가세신고 퀵 가이드_가맹점용.pdf', file: QuickGuide },
];

export const RefundVatDateHeaderTitle = [
  { title: 'No.', flex: 1 },
  { title: '자료명', flex: 15 },
  { title: '등록일', flex: 2.5 },
];
