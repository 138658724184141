import React from 'react';
import styled from 'styled-components';

import RefundDetailGraphItem from './GlobalPaymentItem';

interface IProps {
  data: IDataState[];
  pageNumber: number;
  totalElements: number;
}

interface IDataState {
  actualAmount: number;
  createdDate: string;
  refundIndex: number;
  refundStatus: string;
  totalAmount: string;
  totalRefund: string;
  customerNation: string;
  customerName: string;
}

function GlobalPaymentList({ data, pageNumber, totalElements }: IProps) {
  return (
    <List>
      {data?.map((item, idx) => (
        <RefundDetailGraphItem
          key={idx}
          item={item}
          idx={idx}
          pageNumber={pageNumber}
          totalElements={totalElements}
        />
      ))}
    </List>
  );
}

const List = styled.div``;

export default GlobalPaymentList;
