import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

type SideMenuItemProps = {
  id: number;
  title: string;
  link: string;
  defaultIcon: string;
  activeIcon: string;
};
function SideMenuItem({
  title,
  link,
  defaultIcon,
  activeIcon,
}: SideMenuItemProps) {
  const [isActive, setIsActive] = useState<boolean>(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setIsActive(link === pathname);
  }, [pathname]);

  return (
    <Container active={isActive}>
      <Icon src={isActive ? activeIcon : defaultIcon} />
      <Title active={isActive}>{title}</Title>
    </Container>
  );
}

const Container = styled.li<{ active: boolean }>`
  display: flex;
  gap: 14px;
  padding: 10px 0 10px 40px;

  background-color: ${(props) =>
    props.active ? props.theme.blueColors.scale4 : 'transparent'};
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
const Title = styled.h3<{ active: boolean }>`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  color: ${(props) =>
    props.active ? '#0B42AD' : props.theme.blueColors.scale4};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.lineHeight.small};
  }
`;

export default SideMenuItem;
