import React, { Dispatch, SetStateAction } from 'react';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { useLocation, useNavigate } from 'react-router-dom';
import { changeNewPassword } from '@/api/user';
import { ChangeNewPasswordPayload } from '@/types/login';
import styled from 'styled-components';
import ErrorMessage from '../common/ErrorMessage';
import FlexWrap from '../common/FlexWrap';
import { PassportChahgeProcessType } from './CheckChange';

type NewPasswordProps = {
  setPassportChahgeProcess: Dispatch<SetStateAction<PassportChahgeProcessType>>;
};

type NewPasswordCheckType = {
  newPassword: string;
  newPasswordCheck: string;
};

function NewPassword({ setPassportChahgeProcess }: NewPasswordProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { businessNumber } = location.state;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      newPassword: '',
      newPasswordCheck: '',
    },
  });

  const mutation = useMutation<boolean, AxiosError, ChangeNewPasswordPayload>(
    ['bNumExists'],
    (payload) => changeNewPassword(payload),
    {
      retry: false,
      onSuccess: () => {
        alert(
          '비밀번호가 변경되었습니다.\n변경 된 비밀번호는 KTP 앱에도 적용됩니다.'
        );
        setPassportChahgeProcess('COMPLETE');
      },
      onError: () => {
        alert('비밀번호 변경에 실패했습니다. 다시 시도해주시기를 바랍니다.');
      },
    }
  );

  const onSubmitNewPasswordCheck = ({
    newPassword,
    newPasswordCheck,
  }: NewPasswordCheckType) => {
    if (newPassword !== newPasswordCheck) {
      setError('newPasswordCheck', {
        message: '비밀번호가 일치하지 않습니다. 다시 입력해주세요.',
      });
      return;
    }
    mutation.mutate({ businessNumber, newPassword, newPasswordCheck });
  };

  return (
    <Container>
      <FlexWrap dir='column' alignItems='center' gap='8px'>
        <Title>비밀번호를 재설정해 주세요.</Title>
        <SubTitle>※ 변경 된 비밀번호는 KTP 앱에도 적용됩니다.</SubTitle>
      </FlexWrap>
      <Form onSubmit={handleSubmit(onSubmitNewPasswordCheck)}>
        <Input
          {...register('newPassword', {
            required: '새 비밀번호를 입력하세요.',
            minLength: {
              value: 4,
              message: '비밀번호는 4자리 이상으로 입력해주세요.',
            },
          })}
          type='password'
          placeholder='새 비밀번호 입력 (4자리 이상)'
        />
        {errors.newPassword && (
          <ErrorMessage text={errors.newPassword.message} margin='8px 0 0 0' />
        )}
        <Input
          {...register('newPasswordCheck')}
          type='password'
          placeholder='새 비밀번호 확인'
          style={{ marginTop: '12px' }}
        />
        {errors.newPasswordCheck && (
          <ErrorMessage
            text={errors.newPasswordCheck.message}
            margin='8px 0 0 0'
          />
        )}
        <FlexWrap dir='column' gap='12px' margin='32px 0 0 0'>
          <Button isComplete>완료</Button>
          <Button onClick={() => navigate('/')}>홈으로</Button>
        </FlexWrap>
      </Form>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 42px 60px 42px 60px;
`;
const Title = styled.h2`
  margin-top: 8px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.large};
`;
const SubTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #0b42ad;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  padding: 13px 11px;
  border-radius: 4px;
  width: 450px;
  height: 48px;
  color: ${(props) => props.theme.grayColors.scale1};
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
  :focus {
    border: 1px solid ${(props) => props.theme.priamryColors.primary};
  }
  ::placeholder {
    color: ${(props) => props.theme.grayColors.scale3};
  }
`;

const Button = styled.button<{ isComplete?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  padding: 12px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  border-radius: 2px;
  border: 1px solid #757575;
  color: ${(props) => (props.isComplete ? '#FFFFFF' : '#757575')};
  background-color: ${(props) => (props.isComplete ? '#0B42AD' : '#FFFFFF')};
`;

export default NewPassword;
