import { format } from 'date-fns';
import serviceAliIcon from '@/assets/globalPayment/service_ali.png';
import serviceAliPlusIcon from '@/assets/globalPayment/service_aliPlus.png';
import serviceWechatIcon from '@/assets/globalPayment/service_wechat.png';

export const removeLetter = (text: string | Date) => {
  return text.toString().replace(/\D/g, '');
};
export const getComma = (x: string | number) => {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const formattedRefundStatus = (refundStatus: string | null) => {
  switch (refundStatus) {
    case 'APPROVAL':
      return '승인';
    case 'CANCEL':
      return '취소';
    case 'PRE_APPROVAL':
      return '승인 대기';
    case 'HOLD':
      return '거절[중복취소]';
    case 'CHECK':
      return '선별검사';
    case 'REJECT':
      return '거절';
    default:
      return '-';
  }
};
export const formatISODateString = (str: string, format: string): string => {
  const month = str.slice(5, 7);
  const date = str.slice(8, 10);
  return format
    .replace('mm', month)
    .replace('m', String(+month))
    .replace('dd', date)
    .replace('d', String(+date));
};
export const formatDateYMD = (date: string) => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  return { year, month, day };
};
export const formatDateRefundStatement = (refundStatus: string, date: Date) => {
  const formattedDate = format(date, 'M월');
  return refundStatus === 'APPROVAL' ? formattedDate : '-';
};
export const getDayOfWeek = (dayOfWeekIdx: number) => {
  switch (dayOfWeekIdx) {
    case 1:
      return '월';
    case 2:
      return '화';
    case 3:
      return '수';
    case 4:
      return '목';
    case 5:
      return '금';
    case 6:
      return '토';
    case 7:
      return '일';
    default:
      return '-';
  }
};
export const popularAgentSelect = (agent: string) => {
  switch (agent) {
    case 'ALIPAY':
      return serviceAliIcon;
    case 'ALIPAY_PLUS':
      return serviceAliPlusIcon;
    case 'WECHAT':
      return serviceWechatIcon;
    default:
      return '';
  }
};
export const getSubCompanySerial = (businessNumber: string) => {
  return businessNumber.length <= 10
    ? '0000'
    : businessNumber.substring(10).padStart(4, '0');
};
