import React from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  title?: string;
  onClick: (e?: any) => void;
  margin?: string;
  bgColor?: string;
  color?: string;
  outlineColor?: string;
  size?: 'small' | 'big';
  right?: string;
  bottom?: string;
  height?: string;
  active?: boolean;
}
function ActionBtn({ title, active = true, ...btnProps }: IProps) {
  return (
    <StyledBtn disabled={!active} active={active} {...btnProps}>
      {title}
    </StyledBtn>
  );
}
const StyledBtn = styled.button<IProps>`
  background-color: ${(props) => props.bgColor || '#354eff'};
  border-radius: 4px;
  color: ${(props) => props.color || '#FFFFFF'};
  padding: 0 20px;
  min-width: ${(props) => (props.size === 'big' ? '140px' : '96px')};
  height: ${(props) => props.height || '36px'};
  font-size: 14px;
  margin: ${(props) => props.margin || 0};
  border: ${(props) =>
    props.outlineColor ? `1px solid ${props.outlineColor}` : 'none'};
  cursor: pointer;
  ${(props) =>
    props.right &&
    css`
      position: absolute;
      right: ${props.right};
    `}
  ${(props) =>
    props.bottom &&
    css`
      position: absolute;
      bottom: ${props.bottom};
    `}
  ${(props) =>
    !props.active &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;
export default ActionBtn;