export const getAgeSection = (age: number) => {
  const tenth = Math.floor(age / 10);
  if (tenth <= 1) return '0~19';
  if (tenth >= 7) return '70~100';
  return `${tenth * 10}~${tenth * 10 + 9}`;
};
export const getPaymentMethod = (method: string) => {
  switch (method) {
    case 'CARD':
      return '카드';
    case 'CASH':
      return '현금';
    case 'GLOBAL':
      return '글로벌 결제';
    default:
      return '-';
  }
};
