import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

type TableSectionProps = {
  title: string;
  dateStr: string;
  children?: ReactNode;
  height?: string;
};
function TableSection({ title, dateStr, children, height }: TableSectionProps) {
  return (
    <Container height={height}>
      <DateText>{dateStr}</DateText>
      <Title>{title}</Title>
      {children}
    </Container>
  );
}
const Container = styled.section<{ height?: string }>`
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  background-color: #ffffff;
  padding: 4px;
  border: 1px solid #bdbdbd;
  padding: 28px 16px;
  border-radius: 4px;
  flex: 1;
`;
const Title = styled.h5`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
`;
const DateText = styled.p`
  font-size: 16px;
  color: #0b42ad;
`;
export default TableSection;
