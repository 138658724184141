import React from 'react';

import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '@/components/common/Footer';

function LoginPasswordChange() {
  return (
    <Wrapper>
      <PasswordResetContainer>
        <Outlet />
      </PasswordResetContainer>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  min-height: calc(100vh);
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

const PasswordResetContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export default LoginPasswordChange;
