import React, { useState } from 'react';
import MobileCheck from './MobileCheck';
import NewPassword from './NewPassword';
import PassportChangeComplete from './PassportChangeComplete';

export type PassportChahgeProcessType =
  | 'MOBILE_CHECK'
  | 'NEW_PASSWORD_CHANGE'
  | 'COMPLETE';

function CheckChange() {
  const [passportChahgeProcess, setPassportChahgeProcess] =
    useState<PassportChahgeProcessType>('MOBILE_CHECK');

  return (
    <>
      {passportChahgeProcess === 'MOBILE_CHECK' ? (
        <MobileCheck setPassportChahgeProcess={setPassportChahgeProcess} />
      ) : passportChahgeProcess === 'NEW_PASSWORD_CHANGE' ? (
        <NewPassword setPassportChahgeProcess={setPassportChahgeProcess} />
      ) : (
        <PassportChangeComplete />
      )}
    </>
  );
}

export default CheckChange;
