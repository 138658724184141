import api from '..';
import {
  ChangeNewPasswordBody,
  ChangeNewPasswordPayload,
  ICheckIsOwnerPayload,
  ICheckIsOwnerQuery,
  ISignInPayload,
} from '../../types/login';

// 로그인
export const login = async (payload: ISignInPayload) => {
  const res = await api.postAxios<any, ISignInPayload, null>(
    '/sign-in',
    payload
  );
  return res.data;
};

// 로그아웃
export const logOut = async () => {
  const res = await api.deleteAxios<null, null, null>('/user/sign-out');
  return res.data;
};
// 사업자번호 확인(비밀번호 재설정)
export const checkBNumExists = async (businessNumber: string) => {
  const res = await api.getAxios<boolean, string>(
    `admin/franchisee/password/exists/${businessNumber}`
  );
  return res.data;
};
export const checkIsOwner = async ({
  businessNumber,
  ...rest
}: ICheckIsOwnerPayload) => {
  const res = await api.getAxios<boolean, ICheckIsOwnerQuery>(
    `admin/franchisee/password/selfCertification/${businessNumber}`,
    rest
  );

  return res.data;
};
export const changeNewPassword = async (payload: ChangeNewPasswordPayload) => {
  const { businessNumber, newPassword, newPasswordCheck } = payload;
  const res = await api.patchAxios<boolean, ChangeNewPasswordBody, null>(
    `admin/franchisee/password/out/${businessNumber}`,
    { newPassword, newPasswordCheck }
  );

  return res.data;
};
