import styled, { keyframes } from 'styled-components';
import FlexWrap from './FlexWrap';

type LoadingViewProps = {
  height?: number;
};
function LoadingView({ height }: LoadingViewProps) {
  return (
    <Container height={height}>
      <FlexWrap dir='column' alignItems='center'>
        <LoadingSpinner />
      </FlexWrap>
    </Container>
  );
}
const Container = styled.div<{ height?: number }>`
  width: 100%;
  height: ${(props) => props.height || 280}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const LoadingSpinner = styled.div`
  animation: ${Spin} 700ms linear infinite;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 8px solid #7ca7fa;
  border-top: 8px solid #246cf6;
`;
export default LoadingView;
