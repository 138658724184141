import { AxiosError } from 'axios';
import { add, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import styled, { css } from 'styled-components';

import { getReportData } from '@/api/refund';
import FlexWrap from '@/components/common/FlexWrap';
import MiddleSection from '@/components/salesReport/MiddleSection';
import TopSection from '@/components/salesReport/TopSection';
import DatePicker from '@/components/salesReport/DatePicker';
import BottomSection from '@/components/salesReport/BottomSection';
import LoadingView from '@/components/common/LoadingView';
import { formatISODateString } from '@/util/common';
import { getFullWeekLabel, getWeekPickerData } from '@/util/salesReport';
import { IError } from '@/types/common';
import { GetReportDataResponse } from '@/types/refund';

function SalesReport() {
  const { weekDateObj, weekPickerArr, initialLabel } = getWeekPickerData(10);
  const now = new Date();
  const [isWeekly, setIsWeekly] = useState<boolean>(true);

  const [dateFilter, setDateFilter] = useState({
    startDate: add(weekDateObj[initialLabel]['startDate'], { days: 1 }),
    endDate: add(weekDateObj[initialLabel]['endDate'], { days: 1 }),
    label: initialLabel,
  });
  //
  const { startDate, endDate } = dateFilter;
  const formatedDateStr =
    formatISODateString(startDate.toISOString(), 'm월 d일 - ') +
    formatISODateString(endDate.toISOString(), 'm월 d일');
  const { data, isFetching } = useQuery<
    GetReportDataResponse,
    AxiosError<IError>
  >(
    ['reportData', startDate, endDate],
    () =>
      getReportData({
        filter: isWeekly ? 'WEEK' : 'MONTH',
        startDate,
        endDate,
      }),
    {
      retry: false,
    }
  );

  useEffect(() => {
    if (isWeekly) {
      setDateFilter({
        startDate: add(weekDateObj[initialLabel]['startDate'], { days: 1 }),
        endDate: add(weekDateObj[initialLabel]['endDate'], { days: 1 }),
        label: initialLabel,
      });
    } else {
      const year = now.getFullYear();
      const month = now.getMonth();
      setDateFilter({
        startDate: new Date(year, month, 2),
        endDate: new Date(year, month + 1, 1),
        label: format(now, 'yyyy년 M월'),
      });
    }
  }, [isWeekly]);
  const onChangeMonth = (
    e: { value: number; label: string },
    type: 'YEAR' | 'MONTH',
    startDate: Date,
    endDate: Date
  ) => {
    setDateFilter(() => ({
      startDate:
        type === 'YEAR'
          ? new Date(startDate.setFullYear(e.value))
          : new Date(startDate.setMonth(e.value)),
      endDate:
        type === 'YEAR'
          ? new Date(endDate.setFullYear(e.value))
          : new Date(endDate.setMonth(e.value + 1)),
      label:
        type === 'YEAR'
          ? `${e.value}년 ${startDate.getMonth() - 1}월`
          : `${startDate.getFullYear()}년 ${e.value - 1}월`,
    }));
  };

  const onChangeWeeklyPicker = (e: any) => {
    const {
      label,
      value: { startDate, endDate },
    } = e;
    setDateFilter({
      label,
      startDate: add(startDate, { days: 1 }),
      endDate: add(endDate, { days: 1 }),
    });
  };
  return (
    <Container>
      <Title>매출 리포트</Title>
      <SubContainer>
        <SubTitle>매장 통계</SubTitle>
        <ContentTitle>지난주 환급 대비</ContentTitle>
        {data ? <TopSection data={data.top} /> : <LoadingView height={514} />}
        <ContentTitle>환급 내역 분석</ContentTitle>
        <DateFilterContainer>
          <DateFilterBtn active={isWeekly} onClick={() => setIsWeekly(true)}>
            주간
          </DateFilterBtn>
          <DateFilterBtn active={!isWeekly} onClick={() => setIsWeekly(false)}>
            월간
          </DateFilterBtn>
          <DatePicker
            isWeekly={isWeekly}
            onChangeWeeklyPicker={onChangeWeeklyPicker}
            onChangeMonth={onChangeMonth}
            weekDateObj={weekDateObj}
            weekPickerArr={weekPickerArr}
            startDate={startDate}
            endDate={endDate}
          />
        </DateFilterContainer>
        {data && (
          <MiddleSection
            isWeekly={isWeekly}
            data={data.middle}
            endDate={endDate}
            formatedDateStr={formatedDateStr}
            isFetching={isFetching}
          />
        )}
        {data && (
          <BottomSection
            formatedDateStr={formatedDateStr}
            data={data?.bottom}
            isWeekly={isWeekly}
            endDate={endDate}
          />
        )}
      </SubContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 1200px;
  height: 100vh;
  overflow-y: scroll;
  min-width: 1200px;
`;
const Title = styled.h2`
  border-bottom: 1px solid #bdbdbd;
  font-size: 28px;
  line-height: 32px;
  padding: 24px 40px;
  font-weight: 700;
  color: #0b42ad;
`;
const SubContainer = styled.div`
  padding: 36px 40px;
  min-height: 1640px;
`;
const SubTitle = styled.h3`
  font-size: 26px;
  line-height: 32px;
  padding-bottom: 24px;
  border-bottom: 1px solid #bdbdbd;
  font-weight: 500;
`;
const ContentTitle = styled.h4`
  font-size: 20px;
  margin: 24px 0 20px;
  font-weight: 500;
`;
const DateFilterContainer = styled(FlexWrap)`
  margin-bottom: 40px;
  border-bottom: 1px solid #757575;
`;
const DateFilterBtn = styled.button<{ active?: boolean }>`
  color: #757575;
  position: relative;
  ${(props) =>
    props.active &&
    css`
      color: #0b42ad;
      font-weight: 500;
      ::after {
        content: '';
        width: 80px;
        height: 2px;
        background-color: #0b42ad;
        transform: translate(-55px, 33px);
        position: absolute;
        z-index: 5;
      }
    `}
  width: 80px;
  padding: 12px 0;
  font-size: 18px;
  :hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;
export default SalesReport;
