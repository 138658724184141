import React from 'react';
import styled, { css } from 'styled-components';

import FileDownload from '@/assets/common/file_download.png';
import useRefundVatData from '@/hooks/useRefundVatData';
import { RefundStatementContent } from '@/types/refundStatement';
import FlexWrap from '../common/FlexWrap';

interface RefundVatDataItemProps {
  refundStatementData: RefundStatementContent;
  idx: number;
  totalElements: number;
}

function RefundVatDataItem({
  refundStatementData,
  totalElements,
  idx,
}: RefundVatDataItemProps) {
  const { month, recordCategory, createdDate, refundRecordIndex } =
    refundStatementData;
  const {
    recordFiles,
    checkNewFile,
    checkFileName,
    checkCreated,
    fileAllDownload,
    fileDownload,
    changeToggle,
    isToggle,
    monthHandle,
  } = useRefundVatData(refundRecordIndex);

  return (
    <>
      <Graph>
        <Content flex={1}>
          {checkNewFile(createdDate) ? (
            <IndexText isNew>NEW</IndexText>
          ) : (
            <IndexText>
              {totalElements - idx < 9
                ? '0' + String(totalElements - idx)
                : totalElements - idx}
            </IndexText>
          )}
        </Content>
        <Content style={{ justifyContent: 'flex-start' }} flex={15}>
          <MonthText>{monthHandle(month)}</MonthText>
          <DataText onClick={changeToggle}>
            {checkFileName(recordCategory)}
          </DataText>
        </Content>
        <Content flex={2.5}>{checkCreated(createdDate)}</Content>
      </Graph>
      <SubItem isToggle={isToggle}>
        {recordFiles && (
          <>
            <Content flex={1}>첨부파일</Content>
            <Content flex={15} disablePadding>
              <FlexWrap dir='column' flex='1'>
                <SubContent
                  isHeader
                >{`전체 파일 ${recordFiles.length}개`}</SubContent>
                <DivdeLine />
                <FlexWrap dir='column' gap='15px'>
                  {recordFiles.map((file, idx) => (
                    <SubContent key={idx}>{file.fileName}</SubContent>
                  ))}
                </FlexWrap>
              </FlexWrap>
            </Content>
            <Content flex={2.5}>
              <FlexWrap dir='column' alignItems='center' flex='1'>
                <Downalod onClick={fileAllDownload}>
                  <DownloadIcon src={FileDownload} />
                  전체 다운로드
                </Downalod>
                <DivdeLine />
                <FlexWrap dir='column' gap='15px'>
                  {recordFiles.map((file, idx) => (
                    <Downalod key={idx} onClick={() => fileDownload(file)}>
                      <DownloadIcon src={FileDownload} />
                      파일 다운로드
                    </Downalod>
                  ))}
                </FlexWrap>
              </FlexWrap>
            </Content>
          </>
        )}
      </SubItem>
    </>
  );
}

const Graph = styled.li`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};
  background-color: ${(props) => props.theme.pointColors.white};
  :hover {
    background-color: #f7f9fc;
  }
`;
const Content = styled.div<{
  flex: number;
  disablePadding?: boolean;
}>`
  display: flex;
  flex: ${(props) => props.flex};
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  font-size: 14px;
  line-height: 22px;
  color: #212121;
  :first-child {
    border-left: 1px solid ${(props) => props.theme.grayColors.scale3};
  }
`;
const IndexText = styled.span<{ isNew?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${(props) => (props.isNew ? '#E42939' : '#212121')};
`;
const MonthText = styled.span`
  margin: 0 5px 0 20px;
  padding: 0 12px;
  border-radius: 6px;
  background-color: #0b42ad;
  color: #ffffff;
`;
const DataText = styled.span`
  position: relative;
  cursor: pointer;
  :hover {
    ::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      height: 1px;
      width: 100%;
      background-color: #212121;
    }
  }
`;
const SubItem = styled.div<{ isToggle: boolean }>`
  display: flex;
  justify-content: center;

  background-color: '#F7F9FC';
  max-height: 0px;
  overflow: hidden;
  transition: all 300ms ease;
  ${(props) =>
    props.isToggle &&
    css`
      max-height: 300px;
      border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};
    `}
`;
const SubContent = styled.div<{ isHeader?: boolean }>`
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;
  font-size: 14px;
  line-height: 24px;
  ${(props) =>
    props.isHeader &&
    css`
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    `}
`;
const DivdeLine = styled.div`
  width: calc(100% + 80px);
  height: 1px;
  margin: 12px 0;
  background-color: #bdbdbd;
  transform: translateX(-20px);
`;
const Downalod = styled.div`
  display: flex;
  align-items: center;
  color: #0b42ad;
  cursor: pointer;
`;
const DownloadIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 4px;
`;
export default RefundVatDataItem;
