import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { VictoryTheme } from 'victory-core';
import { VictoryChart } from 'victory-chart';
import { VictoryAxis } from 'victory-axis';
import { VictoryBar } from 'victory-bar';
import { VictoryTooltip } from 'victory-tooltip';
import { isAfter } from 'date-fns';

import ETC from '@/assets/salesReport/nationality/ETC.png';
import Male from '@/assets/salesReport/gender/male.png';
import Female from '@/assets/salesReport/gender/female.png';
import { nationInfo } from '@/constants/salesReport';
import Text from '@/components/common/Text';
import FlexWrap from '@/components/common/FlexWrap';
import TableSection from '@/components/salesReport/TableSection';
import CustomerInfoItem from '@/components/salesReport/customerInfoItem';
import {
  getAgeIcon,
  getDayOfWeek,
  getGraphColor,
  getGraphColorPerMonth,
  getTooltipTextPerMonth,
  getTooltipTextPerWeek,
  getWeekLabel,
} from '@/util/salesReport';
import { GetReportDataResponse } from '@/types/refund';
import useInnerWidth from '@/hooks/useInnerWidth';

type BottomSectionProps = {
  formatedDateStr: string;
  data: GetReportDataResponse['bottom'];
  isWeekly: boolean;
  endDate: Date;
};
function BottomSection({
  formatedDateStr,
  data,
  isWeekly,
  endDate,
}: BottomSectionProps) {
  const { innerWidth } = useInnerWidth();
  const { graphMap, maxAgeGroup, maxGender, maxNation, totalRefundCount } =
    data;
  const [activeTooltip, setActiveTooltip] = useState('');

  const graphMapKeys = Object.keys(graphMap);

  useEffect(() => {
    setActiveTooltip('');
  }, [endDate]);

  return (
    <Container>
      <TableSection
        height='100%'
        title={isWeekly ? '일별 환급건수' : '주차별 환급건수'}
        dateStr={formatedDateStr}
      >
        <FlexWrap justifyContent='space-between'>
          <Text size={18} weight={500}>{`이번 ${
            isWeekly ? '주' : '달'
          } 총 환급건수`}</Text>
          <Text size={20} weight={500}>
            {totalRefundCount ? totalRefundCount + '건' : 0 + '건'}
          </Text>
        </FlexWrap>
        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={{ x: 50 }}
          style={{
            parent: {
              marginTop: -50,
            },
          }}
          padding={{ top: 30, bottom: 20.3, right: 0, left: 0 }}
          height={innerWidth > 1900 ? 130 : 150}
        >
          <VictoryAxis
            offsetY={20}
            style={{
              axis: {
                stroke: '#000000',
              },
              tickLabels: {
                textAlign: 'center',
                fontSize: 6,
              },
              ticks: {
                size: 3,
                padding: -2,
              },
            }}
          />
          <VictoryBar
            cornerRadius={2}
            style={{
              data: {
                fill: ({ index }) =>
                  isWeekly
                    ? getGraphColor(index as number)
                    : getGraphColorPerMonth(index as number),
              },
              parent: {
                borderWidth: 0,
              },
            }}
            data={graphMapKeys.map((v, i) => {
              return isWeekly
                ? {
                    x: ['월', '화', '수', '목', '금', '토', '일'][i],
                    y: graphMap[v],
                    label: `${getTooltipTextPerWeek(v)}\n${graphMap[v]}건`,
                  }
                : {
                    x: getWeekLabel(new Date(v)),
                    y: graphMap[v],
                    label: `${getTooltipTextPerMonth(v)}\n${graphMap[v]}건`,
                  };
            })}
            barRatio={0.8}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onPressIn: () => {
                    return [
                      {
                        target: 'data',
                        mutation: (props) => {
                          setActiveTooltip((prev) =>
                            prev === props.datum.x ? '' : props.datum.x
                          );
                        },
                      },
                    ];
                  },
                },
              },
            ]}
            labelComponent={
              <VictoryTooltip
                constrainToVisibleArea
                renderInPortal={false}
                cornerRadius={2}
                pointerWidth={5}
                pointerLength={6}
                //@ts-ignore
                active={(data) => {
                  return (
                    (data as any)?.datum.x === activeTooltip ||
                    (isAfter(new Date(), endDate)
                      ? false
                      : (data as any)?.datum.x ===
                        (isWeekly ? getDayOfWeek() : getWeekLabel(new Date())))
                  );
                }}
                flyoutPadding={{ top: 3, right: 5, bottom: 3, left: 5 }}
                flyoutStyle={{
                  stroke: 'transparent',
                  fill: '#3A3B3E',
                }}
                style={{
                  fill: '#FFFFFF',
                  fontSize: 6,
                  fontWeight: 500,
                }}
                activateData={true}
              />
            }
          />
        </VictoryChart>
      </TableSection>
      <TableSection height='100%' title='고객분석' dateStr={formatedDateStr}>
        <CustomerInfoItem
          title='최다 방문 국적'
          content={maxNation && nationInfo[maxNation]['label']}
          iconSource={maxNation && (nationInfo[maxNation]['icon'] || ETC)}
          idx={0}
        />
        <CustomerInfoItem
          title='최다 방문 성별'
          content={maxGender && (maxGender === 'M' ? '남성' : '여성')}
          iconSource={maxGender && (maxGender === 'M' ? Male : Female)}
          idx={1}
        />
        <CustomerInfoItem
          title='평균 연령'
          content={maxAgeGroup && maxAgeGroup?.replace('|', '-') + '세'}
          iconSource={
            maxAgeGroup && getAgeIcon(Number(maxAgeGroup?.slice(0, 2)))
          }
          idx={2}
        />
      </TableSection>
    </Container>
  );
}
const Container = styled(FlexWrap)`
  gap: 20px;
  margin: 40px 0;
  padding-bottom: 40px;
  height: 500px;
  @media screen and (min-width: 2000px) {
    height: 560px;
  }
`;
export default BottomSection;
