import React from 'react';
import styled from 'styled-components';

type ImageType = {
  src: string;
  width: string;
  height: string;
  objectFit?: string;
};

function Icon({ src, width, height, objectFit = 'containt' }: ImageType) {
  return (
    <Image src={src} width={width} height={height} objectFit={objectFit} />
  );
}

const Image = styled.img<{ objectFit: string }>`
  object-fit: ${(props) => props.objectFit};
`;

export default Icon;
