import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { checkBNumExists } from '@/api/user';
import { AxiosError } from 'axios';

import { IError } from '@/types/common';
import styled from 'styled-components';
import ErrorMessage from '../common/ErrorMessage';
import FlexWrap from '../common/FlexWrap';

type BusinessNumberCheckProps = {
  businessNumber: string;
};

function BusinessCheck() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      businessNumber: '',
    },
  });

  const mutation = useMutation<boolean, AxiosError<IError>, string>(
    ['bNumExists'],
    (payload) => checkBNumExists(payload),
    {
      retry: false,
      onSuccess: () => {
        navigate('check-change', {
          state: { businessNumber: getValues('businessNumber') },
        });
      },
      onError: () => {
        setError('businessNumber', {
          message: '가입 내역이 존재하지 않습니다. 다시 입력해주세요.',
        });
      },
    }
  );

  const onSubmitBusinessNumberCheck = ({
    businessNumber,
  }: BusinessNumberCheckProps) => {
    mutation.mutate(businessNumber);
  };

  return (
    <Container>
      <Title>
        비밀번호 재설정 전에 <br />
        사업자등록번호를 입력해 주세요.
      </Title>
      <Form onSubmit={handleSubmit(onSubmitBusinessNumberCheck)}>
        <Input
          {...register('businessNumber', {
            required: '사업자번호를 입력해주세요.',
            pattern: {
              value: /^\d{10}$/,
              message: '사업자등록번호는 10자리의 숫자로 입력해주세요.',
            },
          })}
          maxLength={10}
          type='text'
          placeholder='사업자 등록번호 또는 법인사업자번호 입력'
        />
        {errors.businessNumber && (
          <ErrorMessage
            text={errors.businessNumber.message}
            margin='8px 0 0 0'
          />
        )}
        <FlexWrap dir='column' gap='12px' margin='32px 0 0 0'>
          <Button isNext>다음</Button>
          <Button onClick={() => navigate('/')}>홈으로</Button>
        </FlexWrap>
      </Form>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 42px 60px 42px 60px;
`;
const Title = styled.h2`
  margin-top: 8px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.large};
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  padding: 13px 11px;
  border-radius: 4px;
  width: 450px;
  height: 48px;
  color: ${(props) => props.theme.grayColors.scale1};
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
  :focus {
    border: 1px solid ${(props) => props.theme.priamryColors.primary};
  }
  ::placeholder {
    color: ${(props) => props.theme.grayColors.scale3};
  }
`;

const Button = styled.button<{ isNext?: boolean }>`
  width: 450px;
  padding: 9px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 2px;
  border: 1px solid #757575;
  color: ${(props) => (props.isNext ? '#FFFFFF' : '#757575')};
  background-color: ${(props) => (props.isNext ? '#0B42AD' : '#FFFFFF')};
`;

export default BusinessCheck;
