import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dateSelector: 'CUSTOM',
  filter: {
    startDate: new Date(),
    endDate: new Date(),
  },
  size: 10,
  refundFilter: {
    gender: null,
    minAge: 0,
    maxAge: 100,
    nation: null,
    refundStatus: null,
    employee: null,
    paymentMethod: null,
  },
  globalPayFilter: {
    transactionType: null,
    payType: null,
    isRefund: null,
  },
};

const userSlice = createSlice({
  name: 'refund',
  initialState,
  reducers: {
    setDateSeletor: (state, action) => {
      return {
        ...state,
        dateSelector: action.payload,
      };
    },
    setFilterSeletor: (state, action) => {
      return {
        ...state,
        filter: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    },
    setSizeSeletor: (state, action) => {
      return {
        ...state,
        size: action.payload,
      };
    },
    setRefundFilter: (state, action) => {
      return {
        ...state,
        refundFilter: action.payload,
      };
    },
    resetRefundFilter: (state) => {
      return {
        ...state,
        refundFilter: {
          refundStatus: null,
          nation: null,
          gender: null,
          minAge: 0,
          maxAge: 100,
          employee: null,
          paymentMethod: null,
        },
      };
    },
    setGlobalPayRefundFilter: (state, action) => {
      return {
        ...state,
        globalPayFilter: action.payload,
      };
    },
    resetGlobalPayRefundFilter: (state) => {
      return {
        ...state,
        globalPayFilter: {
          transactionType: null,
          payType: null,
          isRefund: null,
        },
      };
    },
  },
});

export const {
  setDateSeletor,
  setFilterSeletor,
  setSizeSeletor,
  setRefundFilter,
  resetRefundFilter,
  setGlobalPayRefundFilter,
  resetGlobalPayRefundFilter,
} = userSlice.actions;
export default userSlice.reducer;
