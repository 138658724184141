import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Icon from '../common/Icon';
import checkCircleIcon from '@/assets/common/check_circle.png';

function PassportChangeComplete() {
  const navigate = useNavigate();
  return (
    <Container>
      <Icon src={checkCircleIcon} width='66px' height='66px' />
      <Title>
        비밀번호 재설정이
        <br />
        완료되었습니다.
      </Title>
      <Button onClick={() => navigate('/')}>로그인 하러가기</Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 42px 60px 42px 60px;
`;
const Title = styled.h2`
  margin-top: 8px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.large};
`;
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  padding: 12px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  border-radius: 2px;
  border: 1px solid #757575;
  color: #ffffff;
  background-color: #0b42ad;
`;

export default PassportChangeComplete;
