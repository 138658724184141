import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  children?: ReactNode;
  dir?: 'row' | 'column';
  alignItems?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around';
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around';
  margin?: string;
  padding?: string;
  gap?: string;
  onClick?: () => void;
  flex?: string;
}
function FlexWrap({ children, dir = 'row', ...divProps }: IProps) {
  return (
    <Container dir={dir} {...divProps}>
      {children}
    </Container>
  );
}
const Container = styled.div<IProps>`
  display: flex;
  flex-direction: ${(props) => props.dir};
  align-items: ${(props) => props.alignItems || 'flex-start'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  gap: ${(props) => props.gap || '0px'};
  padding: ${(props) => props.padding || 0};
  margin: ${(props) => props.margin || 0};
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
`;
export default FlexWrap;
