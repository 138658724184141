import { ISignInResponse } from '@/types/login';
import { createSlice } from '@reduxjs/toolkit';

type inititalType = {
  isLogged: boolean;
  userInfo: ISignInResponse | null;
};

const initialState: inititalType = {
  isLogged: false,
  userInfo: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginState: (state, action) => {
      state.isLogged = action.payload;
    },
    userState: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

export const { loginState, userState } = userSlice.actions;
export default userSlice.reducer;
