import { useState } from 'react';
import { format } from 'date-fns';
import { VictoryTheme } from 'victory-core';
import { VictoryChart } from 'victory-chart';
import { VictoryAxis } from 'victory-axis';
import { VictoryBar } from 'victory-bar';
import { VictoryTooltip } from 'victory-tooltip';
import styled from 'styled-components';

import { getComma, getDayOfWeek } from '@/util/common';
import { RefundSummaryResponse } from '@/types/home';
import useInnerWidth from '@/hooks/useInnerWidth';

type PaymentGraphProps = {
  data: RefundSummaryResponse;
};
function PaymentGraph({ data }: PaymentGraphProps) {
  const { innerWidth } = useInnerWidth();
  const dataCount =
    data?.summaryGraphList?.filter((v) => v.amount !== 0)?.length === 0
      ? 0
      : data?.summaryGraphList?.length;
  const graphData =
    dataCount == 0
      ? null
      : data?.summaryGraphList?.map((v) => ({
          x: v.date,
          y: v.amount,
          label: `${v.date}(${getDayOfWeek(v.day)})\n${getComma(
            Math.round(v.amount)
          )}원`,
        }));
  const [activeTooltip, setActiveTooltip] = useState('');

  return (
    <>
      {graphData ? (
        <Container>
          <VictoryChart
            theme={VictoryTheme.material}
            domainPadding={{
              x:
                dataCount < 4
                  ? 120
                  : dataCount < 9
                  ? 50
                  : dataCount > 50
                  ? 5
                  : 20,
              y: 20,
            }}
            padding={{ bottom: 10.2, right: 0, left: 0, top: 0 }}
            height={70}
            width={
              innerWidth > 2300
                ? innerWidth / 5 + 20
                : innerWidth > 2100
                ? innerWidth / 5
                : innerWidth > 1900
                ? innerWidth / 5 - 15
                : innerWidth > 1500
                ? innerWidth / 5 - 30
                : 230
            }
          >
            <VictoryAxis
              offsetX={50}
              style={{
                axis: {
                  stroke: 'lightgray',
                },
                tickLabels: {
                  textAlign: 'center',
                  display: (tick: any) => {
                    if (data?.summaryGraphList.length < 32) {
                      return 'inline';
                    } else {
                      return new Date(tick.text).getDate() === 1
                        ? 'inline'
                        : 'none';
                    }
                  },
                  fontSize: innerWidth > 2300 ? 5 : 4,
                },
                ticks: {
                  size: 0,
                  padding: -2,
                },
                grid: { stroke: 'transparent' },
              }}
            />
            <VictoryAxis
              offsetY={20}
              dependentAxis
              minDomain={0}
              tickCount={4}
              tickFormat={(t) =>
                `${getComma(t < 1 ? t * 1e12 : t / 10000)}만원`
              }
              style={{
                axis: {
                  stroke: '#FFFFFF',
                },
                tickLabels: {
                  textAlign: 'center',
                  fontSize: 4,
                },
                ticks: {
                  size: 0,
                  padding: 0,
                },
              }}
            />
            <VictoryBar
              cornerRadius={1}
              style={{
                data: {
                  fill: '#7CA7FA',
                },
                parent: {
                  borderWidth: 0,
                },
              }}
              data={graphData}
              barRatio={dataCount === 1 ? 7 : 0.7}
              events={[
                {
                  target: 'data',
                  eventHandlers: {
                    onPressIn: () => {
                      return [
                        {
                          target: 'data',
                          mutation: (props) => {
                            setActiveTooltip((prev) =>
                              prev === props.datum.x ? '' : props.datum.x
                            );
                          },
                        },
                      ];
                    },
                  },
                },
              ]}
              labelComponent={
                <VictoryTooltip
                  constrainToVisibleArea
                  renderInPortal={false}
                  cornerRadius={1}
                  pointerWidth={3}
                  pointerLength={3}
                  //@ts-ignore
                  active={(data) =>
                    (data as any)?.datum.x === activeTooltip ||
                    (data as any)?.datum.x === format(new Date(), 'M/d')
                  }
                  flyoutPadding={{ top: 2, right: 3, bottom: 2, left: 3 }}
                  flyoutStyle={{
                    stroke: 'transparent',
                    fill: '#3A3B3E',
                  }}
                  style={{
                    fill: '#FFFFFF',
                    fontSize: innerWidth < 2200 ? 4 : 4.5,
                    fontWeight: 500,
                  }}
                  activateData={true}
                />
              }
            />
          </VictoryChart>
        </Container>
      ) : (
        <NoneContentContainer>
          선택된 기간 내 승인된 환급 데이터가 없습니다.
        </NoneContentContainer>
      )}
    </>
  );
}

const Container = styled.div`
  width: 100%;
  height: 300px;
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 30px 0;
  overflow-x: scroll;
  border-radius: 8px;
`;
const NoneContentContainer = styled.div`
  background-color: #ffffff;
  color: #3a3b3e;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 30px;
  font-size: 15px;
  font-weight: 500;
`;
export default PaymentGraph;
