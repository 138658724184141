import { useEffect, useState } from 'react';
import { VictoryAxis } from 'victory-axis';
import { VictoryBar } from 'victory-bar';
import { VictoryChart } from 'victory-chart';
import { VictoryTheme } from 'victory-core';
import { VictoryTooltip } from 'victory-tooltip';
import { isAfter } from 'date-fns';

import FlexWrap from '@/components/common/FlexWrap';
import LoadingView from '@/components/common/LoadingView';
import Text from '@/components/common/Text';
import PriceItem from '@/components/salesReport/PriceItem';
import TableSection from '@/components/salesReport/TableSection';
import { GetReportDataResponse } from '@/types/refund';
import { getComma } from '@/util/common';
import {
  getGraphColor,
  getGraphColorPerMonth,
  getTooltipTextPerMonth,
  getTooltipTextPerWeek,
  getWeekLabel,
  getDayOfWeek,
} from '@/util/salesReport';

type MiddleSectionProps = {
  isWeekly: boolean;
  data: GetReportDataResponse['middle'];
  endDate: Date;
  formatedDateStr: string;
  isFetching: boolean;
};
function MiddleSection({
  isWeekly,
  data,
  endDate,
  formatedDateStr,
  isFetching,
}: MiddleSectionProps) {
  const {
    graphMap,
    lastPaymentTotal,
    lastRefundTotal,
    lastTotal,
    thisPaymentTotal,
    thisRefundTotal,
    thisTotal,
  } = data;
  const [activeTooltip, setActiveTooltip] = useState('');

  const graphMapKeys = Object.keys(graphMap);

  useEffect(() => {
    setActiveTooltip('');
  }, [endDate]);

  return (
    <TableSection
      title={isWeekly ? '일별 총 상품금액' : '주별 총 상품금액'}
      dateStr={formatedDateStr}
    >
      <FlexWrap justifyContent='space-between'>
        <Text size={18} weight={500}>
          {isWeekly ? '이번 주 총 상품금액' : '이번 달 총 상품금액'}
        </Text>
        <Text size={20} weight={500}>
          {getComma(thisTotal) + '원'}
        </Text>
      </FlexWrap>
      {isFetching ? (
        <LoadingView />
      ) : (
        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={{ x: 30 }}
          style={{
            parent: {
              marginTop: 10,
            },
          }}
          padding={{ top: 18, bottom: 10.2, right: 0, left: 0 }}
          height={80}
        >
          <VictoryAxis
            offsetY={10}
            style={{
              axis: {
                stroke: '#000000',
              },
              tickLabels: {
                textAlign: 'center',
                fontSize: 3,
              },
              ticks: {
                size: 2,
                padding: -4,
              },
            }}
          />
          <VictoryBar
            cornerRadius={1}
            style={{
              data: {
                fill: ({ index }) =>
                  isWeekly
                    ? getGraphColor(+index!)
                    : getGraphColorPerMonth(+index!),
              },
              parent: {
                borderWidth: 0,
              },
            }}
            data={graphMapKeys.map((v, i) => {
              return isWeekly
                ? {
                    x: ['월', '화', '수', '목', '금', '토', '일'][i],
                    y: graphMap[v],
                    label: `${getTooltipTextPerWeek(v)}\n${getComma(
                      Math.round(graphMap[v])
                    )}원`,
                  }
                : {
                    x: getWeekLabel(new Date(v)),
                    y: graphMap[v],
                    label: `${getTooltipTextPerMonth(v)}\n${getComma(
                      Math.round(graphMap[v])
                    )}원`,
                  };
            })}
            barRatio={0.8}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onPressIn: () => {
                    return [
                      {
                        target: 'data',
                        mutation: (props) => {
                          setActiveTooltip((prev) =>
                            prev === props.datum.x ? '' : props.datum.x
                          );
                        },
                      },
                    ];
                  },
                },
              },
            ]}
            labelComponent={
              <VictoryTooltip
                constrainToVisibleArea
                renderInPortal={false}
                cornerRadius={1}
                pointerWidth={3}
                pointerLength={3}
                //@ts-ignore
                active={(data) =>
                  (data as any)?.datum.x === activeTooltip ||
                  (isAfter(new Date(), endDate)
                    ? false
                    : (data as any)?.datum.x ===
                      (isWeekly ? getDayOfWeek() : getWeekLabel(new Date())))
                }
                flyoutPadding={{ top: 2, right: 3, bottom: 2, left: 3 }}
                flyoutStyle={{
                  stroke: 'transparent',
                  fill: '#3A3B3E',
                }}
                style={{
                  fill: '#FFFFFF',
                  fontSize: 3.5,
                  fontWeight: 500,
                }}
                activateData={true}
              />
            }
          />
        </VictoryChart>
      )}
      <PriceItem
        isWeekly={isWeekly}
        label='상품금액'
        curr={thisTotal}
        prev={lastTotal}
      />
      <PriceItem
        isWeekly={isWeekly}
        label='결제금액'
        curr={thisPaymentTotal}
        prev={lastPaymentTotal}
      />
      <PriceItem
        isWeekly={isWeekly}
        label='환급액'
        curr={thisRefundTotal}
        prev={lastRefundTotal}
      />
    </TableSection>
  );
}
export default MiddleSection;
