import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function VersionCheck() {
  const location = useLocation();

  useEffect(() => {
    axios
      .get(
        'https://s3.ap-northeast-2.amazonaws.com/franchisee.ktaxpay.com/manifest.json'
      )
      .then((res) => {
        const currentVersion = localStorage.getItem('webVersion');
        const newVersion = res.data.web_version;

        if (currentVersion !== newVersion ?? undefined) {
          localStorage.setItem('webVersion', newVersion);
          window.location.reload();
        }
      });
  }, [location]);

  return <></>;
}

export default VersionCheck;
