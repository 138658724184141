import styled, { css } from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import Text from '@/components/common/Text';
import PriceWrap from '@/components/salesReport/PriceWrap';
import { getVariationData } from '@/util/salesReport';
import Increase from '@/assets/salesReport/increase.png';
import Decrease from '@/assets/salesReport/decrease.png';
import { getComma } from '@/util/common';

type Props = {
  isWeekly: boolean;
  prev: number;
  curr: number;
  label: string;
};
function PriceItem({ isWeekly, prev, curr, label }: Props) {
  const { percent, sign } = getVariationData(prev, curr);
  return (
    <Container>
      <Title>{`지난${isWeekly ? '주' : '달'} 총 ${label}`}</Title>
      <VariationInfo dir='column'>
        <PriceWrap
          price={prev}
          unit='원'
          style={{ justifyContent: 'flex-end', marginRight: 8 }}
        />
        <VariationWrap>
          <Text>{`${percent}% | ${getComma(
            prev > curr ? prev - curr : curr - prev
          )} 원 ${sign === undefined ? '-' : ''}`}</Text>
          {sign !== undefined && (
            <Icon src={sign === '+' ? Increase : Decrease} />
          )}
        </VariationWrap>
      </VariationInfo>
    </Container>
  );
}

const Container = styled(FlexWrap)`
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  :last-child {
    border-bottom: 0px;
  }
`;
const VariationInfo = styled(FlexWrap)<{ isRight?: boolean }>`
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
  ${(props) =>
    props.isRight &&
    css`
      border-left-width: 1px;
      border-color: #e5e6e8;
    `}
`;
const VariationWrap = styled(FlexWrap)`
  align-items: center;
  margin-top: 8px;
`;
const Title = styled(Text)`
  font-size: 14px;
  margin: 0 auto;
  color: #5f6165;
  flex: 0.7;
`;
const Icon = styled.img`
  width: 10px;
  height: 8px;
  margin-left: 4px;
`;
export default PriceItem;
