import React, { useState } from 'react';
import styled from 'styled-components';
import QuestionIcon from '@/assets/common/question.png';
import FlexWrap from './FlexWrap';
interface IProps {
  titleList: CardGraph[];
  isGlobalPay?: boolean;
}

interface CardGraph {
  id: number;
  title: string;
  flex: number;
  isActive?: boolean;
}

function TableHeader({ titleList, isGlobalPay }: IProps) {
  const [isActiveTooltip, setIsActiveTooltip] = useState(false);
  return (
    <Container>
      {titleList.map((card) => (
        <CardContainer key={card.id} flex={card.flex}>
          <CardTitle>{card.title}</CardTitle>
          {isGlobalPay && card.isActive && (
            <TooltipWraapper>
              <QuestionWrapper onMouseLeave={() => setIsActiveTooltip(false)}>
                <QuestionImg
                  src={QuestionIcon}
                  onMouseOver={() => setIsActiveTooltip(true)}
                />
              </QuestionWrapper>
              {isActiveTooltip && (
                <Tooltip>
                  <Arrow />
                  <TooltipContent>
                    {`환급 번호는 환급 현황탭에서도 확인 가능합니다.\n(환급 현황 표 좌측 No.열)`}
                  </TooltipContent>
                </Tooltip>
              )}
            </TooltipWraapper>
          )}
        </CardContainer>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background-color: ${(props) => props.theme.blueColors.scale2};
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
`;
const CardContainer = styled.div<{ flex: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  position: relative;
  flex: ${(props) => props.flex};
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};

  :last-child {
    border-right: none;
  }
`;
const CardTitle = styled.h5`
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
`;
const TooltipWraapper = styled.div`
  position: relative;
`;
const QuestionWrapper = styled(FlexWrap)<{ onMouseLeave: any }>`
  width: 25px;
  height: 36px;
  align-items: center;
  margin-left: 3px;
`;
const QuestionImg = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-right: 4px;
  margin-bottom: 2px;
`;
const Tooltip = styled.div`
  position: absolute;
  top: 120%;
  left: -250px;
  background-color: #212121;
  width: 300px;
  z-index: 10;
  border-radius: 4px;
  padding: 8px 12px;
`;
const Arrow = styled.div`
  position: absolute;
  top: -19px;
  right: 30px;
  width: 10px;
  height: 11px;
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #212121;
`;
const TooltipContent = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #ffffff;
  white-space: pre-wrap;
  text-align: left;
`;
export default TableHeader;
