import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import numberCaseIcon from '@/assets/globalPayment/numberCase.png';
import priceIcon from '@/assets/globalPayment/price.png';
import { getComma, popularAgentSelect } from '@/util/common';

interface GlobalPaymentCardProps {
  refundInfo: IRefundGlobalPayResponse;
}

interface IRefundGlobalPayResponse {
  count: number;
  amount: number;
  mostPopularAgent: string;
}

function GlobalPaymentCard({ refundInfo }: GlobalPaymentCardProps) {
  const { count, amount, mostPopularAgent } = refundInfo;

  return (
    <>
      <Summary>
        <Icon src={numberCaseIcon} />
        <FlexWrap dir='column' alignItems='center' flex='1'>
          <Title>총 해외 간편 결제 건수</Title>
          <ValueWrap alignItems='center' justifyContent='center' gap='8px'>
            <ValueText>{count}</ValueText>
            <Text>건</Text>
          </ValueWrap>
        </FlexWrap>
      </Summary>
      <Summary>
        <Icon src={priceIcon} />
        <FlexWrap dir='column' alignItems='center' flex='1'>
          <Title>총 해외 간편 결제 금액</Title>
          <ValueWrap alignItems='center' justifyContent='center' gap='8px'>
            <ValueText>{getComma(amount)}</ValueText>
            <Text>원</Text>
          </ValueWrap>
        </FlexWrap>
      </Summary>
      <Summary>
        {mostPopularAgent ? (
          <Icon src={popularAgentSelect(mostPopularAgent)} />
        ) : (
          <NotIcon></NotIcon>
        )}
        <FlexWrap dir='column' alignItems='center' flex='1'>
          <Title>주요 결제 서비스</Title>
          <ValueWrap alignItems='center' justifyContent='center' gap='8px'>
            <ValueText>{mostPopularAgent ? mostPopularAgent : '-'}</ValueText>
          </ValueWrap>
        </FlexWrap>
      </Summary>
    </>
  );
}
const Summary = styled(FlexWrap)`
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background-color: #ffffff;
  width: 100%;
  padding: 20px 40px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
`;
const Icon = styled.img`
  width: 68px;
  height: 68px;
  object-fit: contain;
  margin-bottom: 8px;
`;
const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
`;
const Title = styled(Text)``;
const ValueText = styled.p`
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  color: #0b42ad;
  margin-bottom: 3px;
`;
const ValueWrap = styled(FlexWrap)`
  width: 100%;
`;
const NotIcon = styled.div`
  width: 68px;
  height: 68px;
  background-color: #f4f8ff;
  border-radius: 50%;
  margin-bottom: 8px;
`;
export default GlobalPaymentCard;
