import Select from 'react-select';
import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import { monthData, yearData } from '@/constants/salesReport';

type DatePickerProps = {
  isWeekly: boolean;
  onChangeWeeklyPicker: (e: any) => void;
  onChangeMonth: (
    e: { value: any; label: string },
    type: 'YEAR' | 'MONTH',
    startDate: Date,
    endDate: Date
  ) => void;
  weekPickerArr: String[];
  weekDateObj: any;
  startDate: Date;
  endDate: Date;
};
function DatePicker({
  isWeekly,
  onChangeWeeklyPicker,
  onChangeMonth,
  weekPickerArr,
  weekDateObj,
  startDate,
  endDate,
}: DatePickerProps) {
  const weeklyPickerOptions = weekPickerArr.map((v) => ({
    value: weekDateObj[v as string],
    label: v,
  }));
  return (
    <>
      {isWeekly ? (
        <Select
          isSearchable={false}
          defaultValue={weeklyPickerOptions[0]}
          onChange={onChangeWeeklyPicker}
          options={weeklyPickerOptions}
          styles={{
            container: (baseStyles, state) => ({
              ...baseStyles,
              width: 200,
              marginLeft: 'auto',
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              marginLeft: 'auto',
            }),
          }}
        />
      ) : (
        <MonthlyPicker>
          <Select
            isSearchable={false}
            defaultValue={yearData[0]}
            onChange={(e) => onChangeMonth(e!, 'YEAR', startDate, endDate)}
            options={yearData}
            styles={{
              container: (baseStyles, state) => ({
                ...baseStyles,
                width: 120,
                marginLeft: 'auto',
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                marginLeft: 'auto',
              }),
            }}
          />
          <Select
            isSearchable={false}
            defaultValue={monthData[new Date().getMonth()]}
            onChange={(e: any) => onChangeMonth(e, 'MONTH', startDate, endDate)}
            options={monthData}
            styles={{
              container: (baseStyles, state) => ({
                ...baseStyles,
                width: 100,
                marginLeft: 8,
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
              }),
            }}
          />
        </MonthlyPicker>
      )}
    </>
  );
}
const MonthlyPicker = styled(FlexWrap)`
  margin-left: auto;
`;
export default DatePicker;
