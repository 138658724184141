import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const initialization = (config: AxiosRequestConfig): AxiosInstance => {
  const axiosInstance = axios.create(config);
  axiosInstance.interceptors.request.use(async (existedConfig) => {
    const token = await localStorage.getItem('loginToken');
    if (!!token) {
      existedConfig.headers.Authorization = `Bearer ${token}`;
    }
    return existedConfig;
  });
  return axiosInstance;
};

export default initialization;
