import { useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import SideNavigation from '@/components/SideNavigation';
import NotSupporteMobile from '@/pages/NotSupporteMobile';
import SalesReport from '@/pages/SalesReport';
import Home from '@/pages/Home';
import Login from '@/pages/Login';
import { loginState } from '@/store/modules/user';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import RefundStatement from '@/pages/RefundStatement';
import LoginPasswordChange from '@/pages/LoginPasswordChange';
import BusinessCheck from '@/components/passwordChange/BusinessCheck';
import CheckChange from '@/components/passwordChange/CheckChange';
import GlobalPayment from '@/pages/GlobalPayment';
import VersionCheck from '@/components/common/VersionCheck';

const RouterContainer = () => {
  const dispatch = useAppDispatch();
  const { isLogged } = useAppSelector((state) => state.user);

  useLayoutEffect(() => {
    if (localStorage.getItem('loginToken')) {
      dispatch(loginState(true));
    }
  }, []);

  return (
    <Router>
      <Wrapper className='notranslate'>
        {isMobile ? (
          <NotSupporteMobile />
        ) : (
          <>
            {<VersionCheck />}
            {isLogged && <SideNavigation />}
            {isLogged ? (
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/salesReport' element={<SalesReport />} />
                <Route path='/refundStatement' element={<RefundStatement />} />
                <Route path='/global-payment' element={<GlobalPayment />} />
              </Routes>
            ) : (
              <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/passport-reset' element={<LoginPasswordChange />}>
                  <Route index element={<BusinessCheck />} />
                  <Route path='check-change' element={<CheckChange />} />
                </Route>
              </Routes>
            )}
          </>
        )}
      </Wrapper>
    </Router>
  );
};

const Wrapper = styled.div`
  display: flex;
  max-width: 100vw;
`;

export default RouterContainer;
