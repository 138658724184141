import { CSSProperties } from 'react';
import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import Text from '@/components/common/Text';
import { getComma } from '@/util/common';

type Props = {
  price: string | number;
  unit?: string;
  style?: CSSProperties;
};
function PriceWrap({ price, unit = '원', style }: Props) {
  return (
    <Container style={style}>
      <Text size={20} weight={500}>
        {getComma(price)}
      </Text>
      <Text margin='0 0 0 4px'>{unit}</Text>
    </Container>
  );
}
const Container = styled(FlexWrap)<{ style?: CSSProperties }>`
  align-items: flex-end;
`;
export default PriceWrap;
